/* eslint-disable */
export const employeesData = [
    {
        "basicSalary": 10664,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.787589Z",
        "departmentName": "Sales",
        "email": "garrett-reeves@company.com",
        "firstName": "Garrett",
        "hireDate": "2017-12-07T00:00:00Z",
        "id": 11,
        "jobTitle": "Junior Sale",
        "lastName": "Reeves",
        "lastUpdated": "2021-12-29T11:03:25.787598Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 622,
        "curGratuity": 30329,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 20217,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.794190Z",
        "departmentName": "Sales",
        "email": "hollee-doyle@company.com",
        "firstName": "Hollee",
        "hireDate": "2013-05-04T00:00:00Z",
        "id": 12,
        "jobTitle": "Manager",
        "lastName": "Doyle",
        "lastUpdated": "2021-12-29T11:03:25.794200Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1684,
        "curGratuity": 144759,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 6547,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.794547Z",
        "departmentName": "Sales",
        "email": "hope-fulton@company.com",
        "firstName": "Hope",
        "hireDate": "2017-02-18T00:00:00Z",
        "id": 13,
        "jobTitle": "Junior Sale",
        "lastName": "Fulton",
        "lastUpdated": "2021-12-29T11:03:25.794555Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 381,
        "curGratuity": 22286,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 24757,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.795479Z",
        "departmentName": "Sales",
        "email": "cadman-shepherd@company.com",
        "firstName": "Cadman",
        "hireDate": "2020-07-16T00:00:00Z",
        "id": 14,
        "jobTitle": "Head of Sales",
        "lastName": "Shepherd",
        "lastUpdated": "2021-12-29T11:03:25.795488Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1444,
        "curGratuity": 25211,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 11998,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.795726Z",
        "departmentName": "Sales",
        "email": "hilel-snow@company.com",
        "firstName": "Hilel",
        "hireDate": "2019-08-20T00:00:00Z",
        "id": 15,
        "jobTitle": "Junior Sale",
        "lastName": "Snow",
        "lastUpdated": "2021-12-29T11:03:25.795735Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 699,
        "curGratuity": 19834,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 13884,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.796007Z",
        "departmentName": "Sales",
        "email": "leonard-bailey@company.com",
        "firstName": "Leonard",
        "hireDate": "2012-12-11T00:00:00Z",
        "id": 16,
        "jobTitle": "Senior Sale",
        "lastName": "Bailey",
        "lastUpdated": "2021-12-29T11:03:25.796016Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1157,
        "curGratuity": 104890,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 17077,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.796194Z",
        "departmentName": "Sales",
        "email": "grant-perez@company.com",
        "firstName": "Grant",
        "hireDate": "2021-11-24T00:00:00Z",
        "id": 17,
        "jobTitle": "Senior Sale",
        "lastName": "Perez",
        "lastUpdated": "2021-12-29T11:03:25.796203Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 3728,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.796453Z",
        "departmentName": "Sales",
        "email": "lysandra-aguilar@company.com",
        "firstName": "Lysandra",
        "hireDate": "2012-08-01T00:00:00Z",
        "id": 18,
        "jobTitle": "Personal Assistant",
        "lastName": "Aguilar",
        "lastUpdated": "2021-12-29T11:03:25.796462Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 310,
        "curGratuity": 29512,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 12130,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.796680Z",
        "departmentName": "Sales",
        "email": "august-sellers@company.com",
        "firstName": "August",
        "hireDate": "2015-03-18T00:00:00Z",
        "id": 19,
        "jobTitle": "Senior Sale",
        "lastName": "Sellers",
        "lastUpdated": "2021-12-29T11:03:25.796688Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1010,
        "curGratuity": 64156,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 7771,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.796939Z",
        "departmentName": "Finance",
        "email": "rajah-ortiz@company.com",
        "firstName": "Rajah",
        "hireDate": "2020-10-28T00:00:00Z",
        "id": 20,
        "jobTitle": "Accounting Assistant",
        "lastName": "Ortiz",
        "lastUpdated": "2021-12-29T11:03:25.796947Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 453,
        "curGratuity": 6363,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 7825,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.797169Z",
        "departmentName": "Finance",
        "email": "rigel-goodman@company.com",
        "firstName": "Rigel",
        "hireDate": "2015-02-17T00:00:00Z",
        "id": 21,
        "jobTitle": "Accounting Assistant",
        "lastName": "Goodman",
        "lastUpdated": "2021-12-29T11:03:25.797177Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 652,
        "curGratuity": 42008,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9294,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.797345Z",
        "departmentName": "Finance",
        "email": "winifred-espinoza@company.com",
        "firstName": "Winifred",
        "hireDate": "2021-02-23T00:00:00Z",
        "id": 22,
        "jobTitle": "Accounting Analyst",
        "lastName": "Espinoza",
        "lastUpdated": "2021-12-29T11:03:25.797353Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 6022,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.797525Z",
        "departmentName": "Finance",
        "email": "fuller-mcfarland@company.com",
        "firstName": "Fuller",
        "hireDate": "2021-12-26T00:00:00Z",
        "id": 23,
        "jobTitle": "Accounting Assistant",
        "lastName": "Mcfarland",
        "lastUpdated": "2021-12-29T11:03:25.797534Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 20313,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.797698Z",
        "departmentName": "Finance",
        "email": "alexandra-collins@company.com",
        "firstName": "Alexandra",
        "hireDate": "2017-05-25T00:00:00Z",
        "id": 24,
        "jobTitle": "Director",
        "lastName": "Collins",
        "lastUpdated": "2021-12-29T11:03:25.797707Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1184,
        "curGratuity": 65407,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 9095,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.797919Z",
        "departmentName": "Finance",
        "email": "kamal-roach@company.com",
        "firstName": "Kamal",
        "hireDate": "2019-01-17T00:00:00Z",
        "id": 25,
        "jobTitle": "Accounting Analyst",
        "lastName": "Roach",
        "lastUpdated": "2021-12-29T11:03:25.797928Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 530,
        "curGratuity": 18785,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 2789,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.798117Z",
        "departmentName": "Finance",
        "email": "jacob-mcdaniel@company.com",
        "firstName": "Jacob",
        "hireDate": "2020-07-07T00:00:00Z",
        "id": 26,
        "jobTitle": "Personal Assistant",
        "lastName": "Mcdaniel",
        "lastUpdated": "2021-12-29T11:03:25.798123Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 162,
        "curGratuity": 2888,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 11226,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.798360Z",
        "departmentName": "Finance",
        "email": "tatiana-burnett@company.com",
        "firstName": "Tatiana",
        "hireDate": "2020-07-14T00:00:00Z",
        "id": 27,
        "jobTitle": "Accountant II",
        "lastName": "Burnett",
        "lastUpdated": "2021-12-29T11:03:25.798369Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 654,
        "curGratuity": 11475,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 14405,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.798649Z",
        "departmentName": "Finance",
        "email": "priscilla-collins@company.com",
        "firstName": "Priscilla",
        "hireDate": "2015-06-19T00:00:00Z",
        "id": 28,
        "jobTitle": "Supervisor",
        "lastName": "Collins",
        "lastUpdated": "2021-12-29T11:03:25.798658Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1200,
        "curGratuity": 72518,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 22091,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.798898Z",
        "departmentName": "Finance",
        "email": "ignatius-mercado@company.com",
        "firstName": "Ignatius",
        "hireDate": "2014-06-11T00:00:00Z",
        "id": 29,
        "jobTitle": "Director",
        "lastName": "Mercado",
        "lastUpdated": "2021-12-29T11:03:25.798906Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1840,
        "curGratuity": 133786,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 12020,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799090Z",
        "departmentName": "Finance",
        "email": "mary-kinney@company.com",
        "firstName": "Mary",
        "hireDate": "2017-09-05T00:00:00Z",
        "id": 30,
        "jobTitle": "Internal Auditor",
        "lastName": "Kinney",
        "lastUpdated": "2021-12-29T11:03:25.799099Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 701,
        "curGratuity": 36330,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 3667,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799254Z",
        "departmentName": "Marketing",
        "email": "breanna-bentley@company.com",
        "firstName": "Breanna",
        "hireDate": "2019-09-14T00:00:00Z",
        "id": 31,
        "jobTitle": "Personal Assistant",
        "lastName": "Bentley",
        "lastUpdated": "2021-12-29T11:03:25.799262Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 213,
        "curGratuity": 5886,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 8521,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799438Z",
        "departmentName": "Marketing",
        "email": "simone-carlson@company.com",
        "firstName": "Simone",
        "hireDate": "2016-09-17T00:00:00Z",
        "id": 32,
        "jobTitle": "Marketing Assistant",
        "lastName": "Carlson",
        "lastUpdated": "2021-12-29T11:03:25.799463Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 710,
        "curGratuity": 32251,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 14614,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799613Z",
        "departmentName": "Marketing",
        "email": "tasha-schmidt@company.com",
        "firstName": "Tasha",
        "hireDate": "2018-03-28T00:00:00Z",
        "id": 33,
        "jobTitle": "Social Media Coordinator",
        "lastName": "Schmidt",
        "lastUpdated": "2021-12-29T11:03:25.799621Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 852,
        "curGratuity": 38452,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 23071,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799837Z",
        "departmentName": "Marketing",
        "email": "alan-calderon@company.com",
        "firstName": "Alan",
        "hireDate": "2021-06-19T00:00:00Z",
        "id": 34,
        "jobTitle": "Marketing Manager",
        "lastName": "Calderon",
        "lastUpdated": "2021-12-29T11:03:25.799846Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 5996,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.799999Z",
        "departmentName": "Marketing",
        "email": "jessica-barber@company.com",
        "firstName": "Jessica",
        "hireDate": "2013-08-14T00:00:00Z",
        "id": 35,
        "jobTitle": "Marketing Assistant",
        "lastName": "Barber",
        "lastUpdated": "2021-12-29T11:03:25.800008Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 499,
        "curGratuity": 41257,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 17149,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.800159Z",
        "departmentName": "Marketing",
        "email": "keith-hill@company.com",
        "firstName": "Keith",
        "hireDate": "2017-06-24T00:00:00Z",
        "id": 36,
        "jobTitle": "Brand Manager",
        "lastName": "Hill",
        "lastUpdated": "2021-12-29T11:03:25.800168Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1000,
        "curGratuity": 54233,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 6777,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.800325Z",
        "departmentName": "Marketing",
        "email": "beck-massey@company.com",
        "firstName": "Beck",
        "hireDate": "2013-04-28T00:00:00Z",
        "id": 37,
        "jobTitle": "Copywriter",
        "lastName": "Massey",
        "lastUpdated": "2021-12-29T11:03:25.800334Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 564,
        "curGratuity": 48636,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 6375,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.800481Z",
        "departmentName": "Marketing",
        "email": "leroy-massey@company.com",
        "firstName": "Leroy",
        "hireDate": "2019-04-02T00:00:00Z",
        "id": 38,
        "jobTitle": "Copywriter",
        "lastName": "Massey",
        "lastUpdated": "2021-12-29T11:03:25.800490Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 371,
        "curGratuity": 12250,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 23803,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.800639Z",
        "departmentName": "Marketing",
        "email": "amaya-newman@company.com",
        "firstName": "Amaya",
        "hireDate": "2012-01-31T00:00:00Z",
        "id": 39,
        "jobTitle": "Marketing Director",
        "lastName": "Newman",
        "lastUpdated": "2021-12-29T11:03:25.800647Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1983,
        "curGratuity": 200369,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 16944,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.800798Z",
        "departmentName": "Marketing",
        "email": "priscilla-holloway@company.com",
        "firstName": "Priscilla",
        "hireDate": "2017-12-12T00:00:00Z",
        "id": 40,
        "jobTitle": "Marketing Manager",
        "lastName": "Holloway",
        "lastUpdated": "2021-12-29T11:03:25.800806Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 988,
        "curGratuity": 48028,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 9025,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801003Z",
        "departmentName": "Marketing",
        "email": "basia-joseph@company.com",
        "firstName": "Basia",
        "hireDate": "2020-03-29T00:00:00Z",
        "id": 41,
        "jobTitle": "Marketing Assistant",
        "lastName": "Joseph",
        "lastUpdated": "2021-12-29T11:03:25.801012Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 526,
        "curGratuity": 11077,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 18380,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801169Z",
        "departmentName": "Marketing",
        "email": "berk-farmer@company.com",
        "firstName": "Berk",
        "hireDate": "2018-12-22T00:00:00Z",
        "id": 42,
        "jobTitle": "Marketing Manager",
        "lastName": "Farmer",
        "lastUpdated": "2021-12-29T11:03:25.801178Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1072,
        "curGratuity": 38879,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 8544,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801331Z",
        "departmentName": "Marketing",
        "email": "emmanuel-mathews@company.com",
        "firstName": "Emmanuel",
        "hireDate": "2019-01-24T00:00:00Z",
        "id": 43,
        "jobTitle": "Marketing Coordinator",
        "lastName": "Mathews",
        "lastUpdated": "2021-12-29T11:03:25.801340Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 498,
        "curGratuity": 17532,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 4141,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801498Z",
        "departmentName": "Engineering",
        "email": "moses-vincent@company.com",
        "firstName": "Moses",
        "hireDate": "2016-08-26T00:00:00Z",
        "id": 44,
        "jobTitle": "DevOps",
        "lastName": "Vincent",
        "lastUpdated": "2021-12-29T11:03:25.801506Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 345,
        "curGratuity": 15922,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 2740,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801657Z",
        "departmentName": "Engineering",
        "email": "flavia-woods@company.com",
        "firstName": "Flavia",
        "hireDate": "2015-01-24T00:00:00Z",
        "id": 45,
        "jobTitle": "DevOps",
        "lastName": "Woods",
        "lastUpdated": "2021-12-29T11:03:25.801664Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 228,
        "curGratuity": 14889,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 23343,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.801815Z",
        "departmentName": "Engineering",
        "email": "rigel-branch@company.com",
        "firstName": "Rigel",
        "hireDate": "2014-04-25T00:00:00Z",
        "id": 46,
        "jobTitle": "Director of Engineering",
        "lastName": "Branch",
        "lastUpdated": "2021-12-29T11:03:25.801824Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1945,
        "curGratuity": 144374,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 6618,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802029Z",
        "departmentName": "Engineering",
        "email": "hilda-little@company.com",
        "firstName": "Hilda",
        "hireDate": "2013-03-11T00:00:00Z",
        "id": 47,
        "jobTitle": "DevOps",
        "lastName": "Little",
        "lastUpdated": "2021-12-29T11:03:25.802037Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 551,
        "curGratuity": 48365,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9734,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802212Z",
        "departmentName": "Engineering",
        "email": "willow-sanders@company.com",
        "firstName": "Willow",
        "hireDate": "2016-08-26T00:00:00Z",
        "id": 48,
        "jobTitle": "DevOps",
        "lastName": "Sanders",
        "lastUpdated": "2021-12-29T11:03:25.802220Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 811,
        "curGratuity": 37429,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 10821,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802388Z",
        "departmentName": "Engineering",
        "email": "brynne-clark@company.com",
        "firstName": "Brynne",
        "hireDate": "2014-12-03T00:00:00Z",
        "id": 49,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Clark",
        "lastUpdated": "2021-12-29T11:03:25.802396Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 901,
        "curGratuity": 60345,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 24061,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802546Z",
        "departmentName": "Engineering",
        "email": "illana-gay@company.com",
        "firstName": "Illana",
        "hireDate": "2020-02-18T00:00:00Z",
        "id": 50,
        "jobTitle": "Director of Engineering",
        "lastName": "Gay",
        "lastUpdated": "2021-12-29T11:03:25.802554Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1403,
        "curGratuity": 31378,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 24650,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802713Z",
        "departmentName": "Engineering",
        "email": "christen-mills@company.com",
        "firstName": "Christen",
        "hireDate": "2018-03-15T00:00:00Z",
        "id": 51,
        "jobTitle": "Director of Engineering",
        "lastName": "Mills",
        "lastUpdated": "2021-12-29T11:03:25.802722Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1437,
        "curGratuity": 65474,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 12779,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.802937Z",
        "departmentName": "Engineering",
        "email": "harlan-cook@company.com",
        "firstName": "Harlan",
        "hireDate": "2020-04-04T00:00:00Z",
        "id": 52,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Cook",
        "lastUpdated": "2021-12-29T11:03:25.802945Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 745,
        "curGratuity": 15537,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 21443,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.803093Z",
        "departmentName": "Engineering",
        "email": "preston-mcgowan@company.com",
        "firstName": "Preston",
        "hireDate": "2017-08-16T00:00:00Z",
        "id": 53,
        "jobTitle": "Lead Product Manager",
        "lastName": "Mcgowan",
        "lastUpdated": "2021-12-29T11:03:25.803101Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1250,
        "curGratuity": 65633,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 11495,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.803295Z",
        "departmentName": "Engineering",
        "email": "lawrence-cole@company.com",
        "firstName": "Lawrence",
        "hireDate": "2021-09-05T00:00:00Z",
        "id": 54,
        "jobTitle": "Front End Engineer",
        "lastName": "Cole",
        "lastUpdated": "2021-12-29T11:03:25.803303Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 21992,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.803449Z",
        "departmentName": "Engineering",
        "email": "hall-randall@company.com",
        "firstName": "Hall",
        "hireDate": "2017-03-04T00:00:00Z",
        "id": 55,
        "jobTitle": "Head of Security",
        "lastName": "Randall",
        "lastUpdated": "2021-12-29T11:03:25.803457Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1282,
        "curGratuity": 74272,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 5725,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.803614Z",
        "departmentName": "Engineering",
        "email": "rogan-patton@company.com",
        "firstName": "Rogan",
        "hireDate": "2015-11-29T00:00:00Z",
        "id": 56,
        "jobTitle": "QA Engineer",
        "lastName": "Patton",
        "lastUpdated": "2021-12-29T11:03:25.803622Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 477,
        "curGratuity": 26264,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9243,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.803767Z",
        "departmentName": "Engineering",
        "email": "daniel-riggs@company.com",
        "firstName": "Daniel",
        "hireDate": "2021-02-12T00:00:00Z",
        "id": 57,
        "jobTitle": "QA Engineer",
        "lastName": "Riggs",
        "lastUpdated": "2021-12-29T11:03:25.803775Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 22447,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804039Z",
        "departmentName": "Engineering",
        "email": "logan-riggs@company.com",
        "firstName": "Logan",
        "hireDate": "2012-08-12T00:00:00Z",
        "id": 58,
        "jobTitle": "Lead Product Manager",
        "lastName": "Riggs",
        "lastUpdated": "2021-12-29T11:03:25.804047Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1870,
        "curGratuity": 177023,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 11524,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804278Z",
        "departmentName": "Engineering",
        "email": "doris-graves@company.com",
        "firstName": "Doris",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 59,
        "jobTitle": "Product Manager",
        "lastName": "Graves",
        "lastUpdated": "2021-12-29T11:03:25.804286Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 960,
        "curGratuity": 97069,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 5729,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804430Z",
        "departmentName": "Engineering",
        "email": "shea-curry@company.com",
        "firstName": "Shea",
        "hireDate": "2017-02-23T00:00:00Z",
        "id": 60,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Curry",
        "lastUpdated": "2021-12-29T11:03:25.804438Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 334,
        "curGratuity": 19447,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 21422,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804587Z",
        "departmentName": "Engineering",
        "email": "erin-forbes@company.com",
        "firstName": "Erin",
        "hireDate": "2014-10-07T00:00:00Z",
        "id": 61,
        "jobTitle": "Director of Engineering",
        "lastName": "Forbes",
        "lastUpdated": "2021-12-29T11:03:25.804595Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1785,
        "curGratuity": 122809,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 18720,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804739Z",
        "departmentName": "Engineering",
        "email": "jared-wise@company.com",
        "firstName": "Jared",
        "hireDate": "2017-04-09T00:00:00Z",
        "id": 62,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Wise",
        "lastUpdated": "2021-12-29T11:03:25.804747Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1092,
        "curGratuity": 61929,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 9518,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.804949Z",
        "departmentName": "Engineering",
        "email": "arthur-pena@company.com",
        "firstName": "Arthur",
        "hireDate": "2013-11-06T00:00:00Z",
        "id": 63,
        "jobTitle": "DevOps",
        "lastName": "Pena",
        "lastUpdated": "2021-12-29T11:03:25.804957Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 793,
        "curGratuity": 63301,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 14095,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805100Z",
        "departmentName": "Engineering",
        "email": "tyler-walls@company.com",
        "firstName": "Tyler",
        "hireDate": "2021-08-04T00:00:00Z",
        "id": 64,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Walls",
        "lastUpdated": "2021-12-29T11:03:25.805108Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 4782,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805271Z",
        "departmentName": "Engineering",
        "email": "iola-day@company.com",
        "firstName": "Iola",
        "hireDate": "2021-10-26T00:00:00Z",
        "id": 65,
        "jobTitle": "DevOps",
        "lastName": "Day",
        "lastUpdated": "2021-12-29T11:03:25.805279Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 21259,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805466Z",
        "departmentName": "Engineering",
        "email": "joy-barber@company.com",
        "firstName": "Joy",
        "hireDate": "2021-07-18T00:00:00Z",
        "id": 66,
        "jobTitle": "Principal Architect",
        "lastName": "Barber",
        "lastUpdated": "2021-12-29T11:03:25.805474Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 22392,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805621Z",
        "departmentName": "Engineering",
        "email": "george-ewing@company.com",
        "firstName": "George",
        "hireDate": "2013-06-25T00:00:00Z",
        "id": 67,
        "jobTitle": "Lead Product Manager",
        "lastName": "Ewing",
        "lastUpdated": "2021-12-29T11:03:25.805630Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1866,
        "curGratuity": 157142,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 3614,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805831Z",
        "departmentName": "Engineering",
        "email": "dominique-nolan@company.com",
        "firstName": "Dominique",
        "hireDate": "2015-12-26T00:00:00Z",
        "id": 68,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Nolan",
        "lastUpdated": "2021-12-29T11:03:25.805839Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 301,
        "curGratuity": 16312,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 11251,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.805976Z",
        "departmentName": "Engineering",
        "email": "ulysses-acevedo@company.com",
        "firstName": "Ulysses",
        "hireDate": "2021-09-06T00:00:00Z",
        "id": 69,
        "jobTitle": "Front End Engineer",
        "lastName": "Acevedo",
        "lastUpdated": "2021-12-29T11:03:25.805984Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 21203,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.806151Z",
        "departmentName": "Engineering",
        "email": "kalia-lamb@company.com",
        "firstName": "Kalia",
        "hireDate": "2018-08-30T00:00:00Z",
        "id": 70,
        "jobTitle": "Security Engineer",
        "lastName": "Lamb",
        "lastUpdated": "2021-12-29T11:03:25.806159Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1236,
        "curGratuity": 49487,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 19736,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.806296Z",
        "departmentName": "Engineering",
        "email": "shannon-gilbert@company.com",
        "firstName": "Shannon",
        "hireDate": "2019-08-26T00:00:00Z",
        "id": 71,
        "jobTitle": "Security Engineer",
        "lastName": "Gilbert",
        "lastUpdated": "2021-12-29T11:03:25.806304Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1151,
        "curGratuity": 32399,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 14842,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.806456Z",
        "departmentName": "Engineering",
        "email": "hedwig-fernandez@company.com",
        "firstName": "Hedwig",
        "hireDate": "2020-04-15T00:00:00Z",
        "id": 72,
        "jobTitle": "Front End Engineer",
        "lastName": "Fernandez",
        "lastUpdated": "2021-12-29T11:03:25.806464Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 865,
        "curGratuity": 17733,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 4746,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.806602Z",
        "departmentName": "Engineering",
        "email": "dean-goff@company.com",
        "firstName": "Dean",
        "hireDate": "2014-06-24T00:00:00Z",
        "id": 73,
        "jobTitle": "Front End Engineer",
        "lastName": "Goff",
        "lastUpdated": "2021-12-29T11:03:25.806610Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 395,
        "curGratuity": 28573,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9477,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.806784Z",
        "departmentName": "Engineering",
        "email": "harper-rosa@company.com",
        "firstName": "Harper",
        "hireDate": "2019-06-27T00:00:00Z",
        "id": 74,
        "jobTitle": "Front End Engineer",
        "lastName": "Rosa",
        "lastUpdated": "2021-12-29T11:03:25.806792Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 552,
        "curGratuity": 16648,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 17398,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807009Z",
        "departmentName": "Engineering",
        "email": "paul-jenkins@company.com",
        "firstName": "Paul",
        "hireDate": "2016-11-08T00:00:00Z",
        "id": 75,
        "jobTitle": "Product Manager",
        "lastName": "Jenkins",
        "lastUpdated": "2021-12-29T11:03:25.807018Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1449,
        "curGratuity": 63371,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 17269,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807156Z",
        "departmentName": "Engineering",
        "email": "jenna-savage@company.com",
        "firstName": "Jenna",
        "hireDate": "2016-02-25T00:00:00Z",
        "id": 76,
        "jobTitle": "Product Manager",
        "lastName": "Savage",
        "lastUpdated": "2021-12-29T11:03:25.807164Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1439,
        "curGratuity": 75061,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 12077,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807302Z",
        "departmentName": "Engineering",
        "email": "kaden-watkins@company.com",
        "firstName": "Kaden",
        "hireDate": "2021-01-13T00:00:00Z",
        "id": 77,
        "jobTitle": "Product Manager",
        "lastName": "Watkins",
        "lastUpdated": "2021-12-29T11:03:25.807310Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 11058,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807451Z",
        "departmentName": "Engineering",
        "email": "melodie-chase@company.com",
        "firstName": "Melodie",
        "hireDate": "2021-07-06T00:00:00Z",
        "id": 78,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Chase",
        "lastUpdated": "2021-12-29T11:03:25.807459Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 0,
        "curGratuity": 0,
        "eligibilityTier": "<1Y"
    },
    {
        "basicSalary": 3237,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807596Z",
        "departmentName": "Engineering",
        "email": "clark-knowles@company.com",
        "firstName": "Clark",
        "hireDate": "2016-08-26T00:00:00Z",
        "id": 79,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Knowles",
        "lastUpdated": "2021-12-29T11:03:25.807604Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 269,
        "curGratuity": 12446,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9137,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807744Z",
        "departmentName": "Engineering",
        "email": "april-battle@company.com",
        "firstName": "April",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 80,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Battle",
        "lastUpdated": "2021-12-29T11:03:25.807752Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 761,
        "curGratuity": 76963,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 12163,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.807949Z",
        "departmentName": "Engineering",
        "email": "tana-greene@company.com",
        "firstName": "Tana",
        "hireDate": "2019-08-18T00:00:00Z",
        "id": 81,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Greene",
        "lastUpdated": "2021-12-29T11:03:25.807957Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 709,
        "curGratuity": 20153,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 3894,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808097Z",
        "departmentName": "Engineering",
        "email": "lars-ayers@company.com",
        "firstName": "Lars",
        "hireDate": "2020-01-09T00:00:00Z",
        "id": 82,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Ayers",
        "lastUpdated": "2021-12-29T11:03:25.808105Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 227,
        "curGratuity": 5376,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 7170,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808244Z",
        "departmentName": "Engineering",
        "email": "ronan-carey@company.com",
        "firstName": "Ronan",
        "hireDate": "2015-06-30T00:00:00Z",
        "id": 83,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Carey",
        "lastUpdated": "2021-12-29T11:03:25.808252Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 597,
        "curGratuity": 35879,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 23509,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808403Z",
        "departmentName": "Engineering",
        "email": "griffin-hopkins@company.com",
        "firstName": "Griffin",
        "hireDate": "2014-11-27T00:00:00Z",
        "id": 84,
        "jobTitle": "Head of Infrastructure",
        "lastName": "Hopkins",
        "lastUpdated": "2021-12-29T11:03:25.808411Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1959,
        "curGratuity": 131489,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 17071,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808551Z",
        "departmentName": "Engineering",
        "email": "fatima-duncan@company.com",
        "firstName": "Fatima",
        "hireDate": "2014-04-15T00:00:00Z",
        "id": 85,
        "jobTitle": "QA Engineer",
        "lastName": "Duncan",
        "lastUpdated": "2021-12-29T11:03:25.808560Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1422,
        "curGratuity": 106050,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 9935,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808696Z",
        "departmentName": "Engineering",
        "email": "hall-rivera@company.com",
        "firstName": "Hall",
        "hireDate": "2014-02-14T00:00:00Z",
        "id": 86,
        "jobTitle": "QA Engineer",
        "lastName": "Rivera",
        "lastUpdated": "2021-12-29T11:03:25.808704Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 827,
        "curGratuity": 63352,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 16299,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.808916Z",
        "departmentName": "Engineering",
        "email": "neil-cotton@company.com",
        "firstName": "Neil",
        "hireDate": "2019-11-08T00:00:00Z",
        "id": 87,
        "jobTitle": "QA Engineer",
        "lastName": "Cotton",
        "lastUpdated": "2021-12-29T11:03:25.808925Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 950,
        "curGratuity": 24444,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 23968,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809076Z",
        "departmentName": "Engineering",
        "email": "patience-mcdonald@company.com",
        "firstName": "Patience",
        "hireDate": "2017-06-21T00:00:00Z",
        "id": 88,
        "jobTitle": "Principal Engineer",
        "lastName": "Mcdonald",
        "lastUpdated": "2021-12-29T11:03:25.809084Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1398,
        "curGratuity": 75935,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 16490,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809232Z",
        "departmentName": "Engineering",
        "email": "leandra-conley@company.com",
        "firstName": "Leandra",
        "hireDate": "2020-01-06T00:00:00Z",
        "id": 89,
        "jobTitle": "DevOps",
        "lastName": "Conley",
        "lastUpdated": "2021-12-29T11:03:25.809240Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 961,
        "curGratuity": 22864,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 16247,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809378Z",
        "departmentName": "Engineering",
        "email": "eden-head@company.com",
        "firstName": "Eden",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 90,
        "jobTitle": "DevOps",
        "lastName": "Head",
        "lastUpdated": "2021-12-29T11:03:25.809386Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1353,
        "curGratuity": 136853,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 4014,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809594Z",
        "departmentName": "Engineering",
        "email": "jeanette-cunningham@company.com",
        "firstName": "Jeanette",
        "hireDate": "2012-04-26T00:00:00Z",
        "id": 91,
        "jobTitle": "DevOps",
        "lastName": "Cunningham",
        "lastUpdated": "2021-12-29T11:03:25.809602Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 334,
        "curGratuity": 32843,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 7783,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809739Z",
        "departmentName": "Engineering",
        "email": "kaden-petty@company.com",
        "firstName": "Kaden",
        "hireDate": "2012-04-17T00:00:00Z",
        "id": 92,
        "jobTitle": "DevOps",
        "lastName": "Petty",
        "lastUpdated": "2021-12-29T11:03:25.809747Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 648,
        "curGratuity": 63873,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 20560,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.809948Z",
        "departmentName": "Engineering",
        "email": "tara-fields@company.com",
        "firstName": "Tara",
        "hireDate": "2013-02-15T00:00:00Z",
        "id": 93,
        "jobTitle": "Head of DataPlatform",
        "lastName": "Fields",
        "lastUpdated": "2021-12-29T11:03:25.809957Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1713,
        "curGratuity": 151608,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 4717,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810114Z",
        "departmentName": "Engineering",
        "email": "declan-dyer@company.com",
        "firstName": "Declan",
        "hireDate": "2017-06-18T00:00:00Z",
        "id": 94,
        "jobTitle": "IT Support",
        "lastName": "Dyer",
        "lastUpdated": "2021-12-29T11:03:25.810122Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 275,
        "curGratuity": 14971,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 23373,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810280Z",
        "departmentName": "Engineering",
        "email": "timon-hart@company.com",
        "firstName": "Timon",
        "hireDate": "2019-10-06T00:00:00Z",
        "id": 95,
        "jobTitle": "Head of Design",
        "lastName": "Hart",
        "lastUpdated": "2021-12-29T11:03:25.810288Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1363,
        "curGratuity": 36532,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 6533,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810433Z",
        "departmentName": "Engineering",
        "email": "nomlanga-contreras@company.com",
        "firstName": "Nomlanga",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 96,
        "jobTitle": "IT Support",
        "lastName": "Contreras",
        "lastUpdated": "2021-12-29T11:03:25.810441Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 544,
        "curGratuity": 55029,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 14562,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810577Z",
        "departmentName": "Engineering",
        "email": "zephr-murphy@company.com",
        "firstName": "Zephr",
        "hireDate": "2018-10-11T00:00:00Z",
        "id": 97,
        "jobTitle": "UI/UX Designer",
        "lastName": "Murphy",
        "lastUpdated": "2021-12-29T11:03:25.810585Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 849,
        "curGratuity": 32814,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 3037,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810721Z",
        "departmentName": "Engineering",
        "email": "claire-preston@company.com",
        "firstName": "Claire",
        "hireDate": "2015-10-07T00:00:00Z",
        "id": 98,
        "jobTitle": "IT Support",
        "lastName": "Preston",
        "lastUpdated": "2021-12-29T11:03:25.810729Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 253,
        "curGratuity": 14373,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 16011,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.810948Z",
        "departmentName": "Engineering",
        "email": "libby-randall@company.com",
        "firstName": "Libby",
        "hireDate": "2019-10-17T00:00:00Z",
        "id": 99,
        "jobTitle": "Data Engineer",
        "lastName": "Randall",
        "lastUpdated": "2021-12-29T11:03:25.810994Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 933,
        "curGratuity": 24687,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 9287,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.811253Z",
        "departmentName": "Engineering",
        "email": "xander-mathews@company.com",
        "firstName": "Xander",
        "hireDate": "2014-08-21T00:00:00Z",
        "id": 100,
        "jobTitle": "Data Scientist",
        "lastName": "Mathews",
        "lastUpdated": "2021-12-29T11:03:25.811261Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 773,
        "curGratuity": 54437,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 16727,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.811412Z",
        "departmentName": "Engineering",
        "email": "ian-mcdonald@company.com",
        "firstName": "Ian",
        "hireDate": "2012-03-17T00:00:00Z",
        "id": 101,
        "jobTitle": "Data Engineer",
        "lastName": "Mcdonald",
        "lastUpdated": "2021-12-29T11:03:25.811420Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1393,
        "curGratuity": 138696,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 19054,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.811553Z",
        "departmentName": "Engineering",
        "email": "cairo-owens@company.com",
        "firstName": "Cairo",
        "hireDate": "2015-11-06T00:00:00Z",
        "id": 102,
        "jobTitle": "Data Engineer",
        "lastName": "Owens",
        "lastUpdated": "2021-12-29T11:03:25.811561Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1587,
        "curGratuity": 88614,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 10402,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.811693Z",
        "departmentName": "Engineering",
        "email": "carissa-powell@company.com",
        "firstName": "Carissa",
        "hireDate": "2015-06-30T00:00:00Z",
        "id": 103,
        "jobTitle": "Data Scientist",
        "lastName": "Powell",
        "lastUpdated": "2021-12-29T11:03:25.811700Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 866,
        "curGratuity": 52052,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 12355,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.811835Z",
        "departmentName": "Engineering",
        "email": "abdul-nieves@company.com",
        "firstName": "Abdul",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 104,
        "jobTitle": "Data Scientist",
        "lastName": "Nieves",
        "lastUpdated": "2021-12-29T11:03:25.811892Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1029,
        "curGratuity": 104069,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 20904,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812025Z",
        "departmentName": "Engineering",
        "email": "rina-fuller@company.com",
        "firstName": "Rina",
        "hireDate": "2016-12-16T00:00:00Z",
        "id": 105,
        "jobTitle": "Engineering Manager",
        "lastName": "Fuller",
        "lastUpdated": "2021-12-29T11:03:25.812032Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1742,
        "curGratuity": 73965,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 7891,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812168Z",
        "departmentName": "Engineering",
        "email": "austin-sullivan@company.com",
        "firstName": "Austin",
        "hireDate": "2019-06-23T00:00:00Z",
        "id": 106,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Sullivan",
        "lastUpdated": "2021-12-29T11:03:25.812175Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 460,
        "curGratuity": 13922,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 16058,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812337Z",
        "departmentName": "Engineering",
        "email": "cynthia-wheeler@company.com",
        "firstName": "Cynthia",
        "hireDate": "2014-02-18T00:00:00Z",
        "id": 107,
        "jobTitle": "Data Analyst",
        "lastName": "Wheeler",
        "lastUpdated": "2021-12-29T11:03:25.812345Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1338,
        "curGratuity": 102221,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 3857,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812477Z",
        "departmentName": "Engineering",
        "email": "amethyst-humphrey@company.com",
        "firstName": "Amethyst",
        "hireDate": "2019-06-14T00:00:00Z",
        "id": 108,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Humphrey",
        "lastUpdated": "2021-12-29T11:03:25.812485Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 224,
        "curGratuity": 6871,
        "eligibilityTier": "<5Y"
    },
    {
        "basicSalary": 14894,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812612Z",
        "departmentName": "Engineering",
        "email": "raya-fuentes@company.com",
        "firstName": "Raya",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 109,
        "jobTitle": "Data Analyst",
        "lastName": "Fuentes",
        "lastUpdated": "2021-12-29T11:03:25.812620Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 1241,
        "curGratuity": 125456,
        "eligibilityTier": "5Y+"
    },
    {
        "basicSalary": 8155,
        "companyId": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        "creationDate": "2021-12-29T11:03:25.812741Z",
        "departmentName": "Engineering",
        "email": "tyler-ellison@company.com",
        "firstName": "Tyler",
        "hireDate": "2012-01-29T00:00:00Z",
        "id": 110,
        "jobTitle": "BackEnd Engineer",
        "lastName": "Ellison",
        "lastUpdated": "2021-12-29T11:03:25.812749Z",
        "middleName": null,
        "offboardingDate": null,
        "offboardingDetails": null,
        "uniqueID": null,
        "unlimitedContract": null,
        "monthlyGratuity": 679,
        "curGratuity": 68691,
        "eligibilityTier": "5Y+"
    }
]