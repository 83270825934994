import mock from "./mock";
/* eslint-disable global-require */
/* eslint-disable  */

if (process.env.REACT_APP_MOCK_BE === "true") {
  console.log("Mocking backend...");
  require("./data/dashboard");
  require("./data/employees");
  require("./data/invitations");
  require("./data/simulations");
  require("./data/admin");
  require("./data/cash");
  require("./data/investments");
}

if (process.env.REACT_APP_MOCK_AUTH === "true") {
  console.log("Mocking Authentication...");
  require("./data/auth");
}

/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
