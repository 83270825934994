import { instance } from "../../../utility";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };
  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    instance.interceptors?.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    // instance.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     // ** const { config, response: { status } } = error
    //     const { config, response } = error;
    //     const originalRequest = config;

    //     // ** if (status === 401) {
    //     // if (response && [401, 403].includes(error.response.status))
    //     if (response && error.response.status === 401) {
    //       if (!this.isAlreadyFetchingAccessToken) {
    //         this.isAlreadyFetchingAccessToken = true;
    //         this.refreshToken().then((r) => {
    //           this.isAlreadyFetchingAccessToken = false;

    //           // ** Update accessToken in localStorage
    //           this.setToken(r.data.access_token);
    //           this.setRefreshToken(r.data.refresh_token);

    //           this.onAccessTokenFetched(r.data.accessToken);
    //         });
    //       }
    //       const retryOriginalRequest = new Promise((resolve, reject) => {
    //         this.addSubscriber((accessToken) => {
    //           // ** Make sure to assign accessToken according to your response.
    //           // ** Check: https://pixinvent.ticksy.com/ticket/2413870
    //           // ** Change Authorization header
    //           originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
    //           resolve(instance(originalRequest));
    //         });
    //         return reject(error);
    //       });
    //       return retryOriginalRequest;
    //     }
    //     return Promise.reject(error);
    //   }
    // );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return sessionStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    sessionStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    sessionStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return instance.post(this.jwtConfig.loginEndpoint, ...args);
  }
  signout() {
    sessionStorage.clear();
    window.location.pathname = "/login";
  }

  register(...args) {
    return instance.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    const params = new URLSearchParams();
    params.append(
      "client_id",
      process.env.REACT_APP_AUTH0_SERVER_CLIENT_SECRET
    );
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", this.getRefreshToken());
    params.append(
      "client_secret",
      process.env.REACT_APP_AUTH0_SERVER_CLIENT_SECRET
    );
    params.append("audience", process.env.REACT_APP_AUTH0_AUDIENCE);
    params.append("scope", "offline_access");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },

      baseURL: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
    };

    return instance
      .post("/oauth/token", params, config)
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem("accessToken", res.data?.access_token);
          sessionStorage.setItem("refreshToken", res.data?.refresh_token);
        }
      })
      .catch((err) => {
        throw err;
      });
    // return instance.post(this.jwtConfig.refreshEndpoint, {
    //   refreshToken: this.getRefreshToken(),
    // });
  }
}
