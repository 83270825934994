/* eslint-disable */
import mock from "../mock";
// eslint-disable-next-line import/no-cycle
import useJwt from "../../auth/jwt/useJwt";
/* eslint-disable global-require */
import jwt from "jsonwebtoken";
import { paginateArray, sortCompare } from "../../utility/fakeDbUtils";
import { accounts } from "./accounts";

const data = {
  companies: [
    {
      id: 1,
      companyId: "3e58df50-ce5c-486f-a394-6a643525e2e9",
      companyName: "Finflx",
      countryCode: "AE",
      address: "HD 55A, Floor 1, In5 Tech",
      domain: "finflx.com",
      city: "Dubai",
      otherCity: null,
      legalName: "Finflx Technology Solutions FZ-LLC",
      tradeLicenseNumber: "98258",
      licenseExpiryDate: "2022-02-16T00:00:00Z",
      licensingAuthorityCode: "FA-AE-1",
      otherLicensingAuthority: null,
      businessActivityCodes: "FA-AE-1-100037,FA-AE-1-100039,FA-AE-1-100041",
      otherBusinessActivity: null,
      enabled: true,
      onboardingStatus: "REGISTERED",
      creationDate: "2021-04-12T09:30:37.534101Z",
      lastUpdated: "2021-04-12T09:30:37.534112Z",
    },
    {
      id: 2,
      companyId: "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
      companyName: "Company",
      countryCode: "AE",
      address: "BD 33A, Floor 4, In2 Tech",
      domain: "test.com",
      city: "Dubai",
      otherCity: null,
      legalName: "Test LLC",
      tradeLicenseNumber: "83942",
      licenseExpiryDate: "2023-05-26T00:00:00Z",
      licensingAuthorityCode: "FA-AE-1",
      otherLicensingAuthority: null,
      businessActivityCodes: "FA-AE-1-452051",
      otherBusinessActivity: null,
      enabled: true,
      onboardingStatus: "CREATED",
      creationDate: "2021-04-22T09:50:37.534101Z",
      lastUpdated: "2021-04-22T09:50:37.534112Z",
    },
  ],
  invitations: {
    content: [
      {
        id: 1,
        firstName: "Eosb",
        lastName: "Admin",
        email: "eosb.admin@finflx.com",
        creationDate: "2021-08-05T08:50:16.420413Z",
        lastUpdated: "2021-08-05T08:50:16.420426Z",
        expiryDate: "2021-09-04T08:50:16.420461Z",
        roles: "eosb-admin",
        redirectUri: "https://finflxacademy.learnworlds.com",
        companyId: "3e58df50-ce5c-486f-a394-6a643525e2e9",
        userId: 1,
        status: "PROCESSED",
      },
      {
        id: 2,
        firstName: "Matthieu",
        lastName: "Capelle",
        email: "matthieu.capelle@finflx.com",
        creationDate: "2021-08-05T08:50:16.420512Z",
        lastUpdated: "2021-08-05T08:50:16.420520Z",
        expiryDate: "2021-09-04T08:50:16.420530Z",
        roles: "academy-user",
        redirectUri: "https://finflxacademy.learnworlds.com",
        companyId: "3e58df50-ce5c-486f-a394-6a643525e2e9",
        status: "SENT",
      },
      {
        id: 3,
        firstName: "Admin",
        lastName: "User",
        email: "admin@company.com",
        creationDate: "2021-08-05T08:57:12.724403Z",
        lastUpdated: "2021-08-05T08:57:12.724412Z",
        expiryDate: "2021-09-04T08:57:12.724427Z",
        roles: "academy-user,eosb-company-admin",
        redirectUri: "https://finflxacademy.learnworlds.com",
        companyId: "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        userId: 2,
        status: "PROCESSED",
      },
      {
        id: 4,
        firstName: "Test",
        lastName: "User",
        email: "test@company.com",
        creationDate: "2021-08-05T08:57:12.724403Z",
        lastUpdated: "2021-08-05T08:57:12.724412Z",
        expiryDate: "2021-09-04T08:57:12.724427Z",
        roles: "academy-user",
        redirectUri: "https://finflxacademy.learnworlds.com",
        companyId: "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        status: "REQUESTED",
      },
      {
        id: 5,
        firstName: "Academy",
        lastName: "User",
        email: "academy@company.com",
        creationDate: "2021-08-05T08:57:12.724403Z",
        lastUpdated: "2021-08-05T08:57:12.724412Z",
        expiryDate: "2021-09-04T08:57:12.724427Z",
        roles: "academy-user",
        redirectUri: "https://finflxacademy.learnworlds.com",
        companyId: "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
        status: "SENT",
      },
    ],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageSize: 20,
      pageNumber: 0,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalPages: 1,
    last: true,
    totalElements: 5,
    first: true,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: 5,
    size: 20,
    number: 0,
    empty: false,
  },
  financialAuthorities: [
    {
      countryCode: "AE",
      city: "Ajman",
      financialAuthority: "DEPARTMENT OF ECONOMIC DEVELOPMENT (MAINLAND)",
      financialAuthorityCode: "FA-AE-1",
    },
    {
      countryCode: "AE",
      city: "Dubai",
      financialAuthority: "DEPARTMENT OF ECONOMIC DEVELOPMENT (MAINLAND)",
      financialAuthorityCode: "FA-AE-1",
    },
    {
      countryCode: "AE",
      city: "Abu Dhabi",
      financialAuthority: "ABU DHABI AIRPORT FREE ZONE (ADAFZ)",
      financialAuthorityCode: "FA-AE-2",
    },
    {
      countryCode: "AE",
      city: "Abu Dhabi",
      financialAuthority: "HIGHER CORPORATION FOR SPECIALISED ECONOMIC ZONES",
      financialAuthorityCode: "FA-AE-6",
    },
    {
      countryCode: "AE",
      city: "Dubai",
      financialAuthority: "JEBEL ALI FREE ZONE",
      financialAuthorityCode: "FA-AE-30",
    },
  ],
  companyRoles: [
    "eosb-finance",
    "eosb-company-admin",
    "eosb-hr",
    "eosb-user",
    "academy-user",
  ],
  activities: [
    {
      activityCode: "FA-AE-1-100037",
      activityCategory: "Agriculture",
      activityName: "Advanced Growing of Beverage Crops",
    },
    {
      activityCode: "FA-AE-1-100039",
      activityCategory: "Agriculture",
      activityName: "Advanced Growing of Citrus Fruits",
    },
    {
      activityCode: "FA-AE-1-100041",
      activityCategory: "Agriculture",
      activityName: "Advanced Growing of Grains, Leguminous Crop & Oilseeds",
    },
    {
      activityCode: "FA-AE-1-924915",
      activityCategory: "Agriculture",
      activityName: "Sports and Recreational Services (Only for Nakheel)",
    },
    {
      activityCode: "FA-AE-1-14009",
      activityCategory: "Agriculture",
      activityName: "Trees Removal & Transplanting Services ",
    },
    {
      activityCode: "FA-AE-1-100179",
      activityCategory: "Agriculture",
      activityName: "Vehicles Registration Services",
    },
    {
      activityCode: "FA-AE-1-452051",
      activityCategory: "Classification Service",
      activityName: "(Palm Jumaira Project only)",
    },
    {
      activityCode: "FA-AE-1-451020",
      activityCategory: "Classification Service",
      activityName:
        "Acquiring and execution of civil works including drilling in areas under the jurisdiction of Jebel Ali Free Zone Authority",
    },
    {
      activityCode: "FA-AE-1-452041",
      activityCategory: "Classification Service",
      activityName:
        "Acquiring and execution of marine construction and reclamation work in general outside the UAE and in particular for Nakheel corporation in Dubai, and storage of construction equipment.",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/api\/user-accounts\/\d+/).reply((config) => {
  // Get event id from URL
  const userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  const userIndex = accounts.findIndex(
    (c) => c.id.toString() === userId.toString()
  );
  const user = accounts[userIndex];

  if (user) return [200, user];

  return [404];
});

// ------------------------------------------------
// GET: Return companies
// ------------------------------------------------
mock.onGet("/api/companies").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", perPage = 10, page = 1, sort = "id,desc" } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();

  /* eslint-disable */
  let filteredData;
  try {
    filteredData = data.companies.filter(
      (company) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        company.companyName.toLowerCase().includes(queryLowered) ||
        company.address.toLowerCase().includes(queryLowered) ||
        company.legalName.toLowerCase().includes(queryLowered) ||
        company.licensingAuthorityCode.toLowerCase().includes(queryLowered) ||
        company.businessActivityCodes.toLowerCase().includes(queryLowered)
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "asc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// GET: Return Single company
// ------------------------------------------------
mock.onGet(/\/api\/companies\/\d+/).reply((config) => {
  // Get event id from URL
  const companyId = config.url.substring(config.url.lastIndexOf("/") + 1);

  const companyIndex = data.companies.findIndex(
    (c) => c.id.toString() === companyId.toString()
  );
  const company = data.companies[companyIndex];

  if (company) return [200, company];

  return [404];
});

// ------------------------------------------------
// GET: Return My company
// ------------------------------------------------
mock.onGet("/api/companies/me").reply(() => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const userIndex = accounts.findIndex(
    (c) => c.email.toString() === userData.email.toString()
  );

  const user = accounts[userIndex];

  const companyIndex = data.companies.findIndex(
    (c) => c.companyId.toString() === user.companyId.toString()
  );
  const company = data.companies[companyIndex];

  if (company) return [200, company];

  return [404];
});

// ------------------------------------------------
// POST: Register a new Company
// ------------------------------------------------
// mock.onPost('/api/companies/register').reply(() => [201])
mock.onPost("/api/companies/register").reply(() => [409, { id: 1 }]);

// ------------------------------------------------
// POST: Join an existing Company
// ------------------------------------------------
mock.onPost(/\/api\/companies\/\d+\/join/).reply(() => [200]);
/* mock.onPost(/\/api\/companies\/\d+\/join/).reply(request => {
  console.log(`join: ${JSON.stringify(request.data)}`)
  return [200]
}) */

// ------------------------------------------------
// GET: Return user accounts
// ------------------------------------------------
mock.onGet("/api/user-accounts").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 0,
    sort = "id,desc",
    companyId = null,
  } = config.params;
  /* eslint-enable */
  const queryLowered = q.toLowerCase();

  /* eslint-disable */
  let filteredData;
  try {
    filteredData = accounts.filter(
      (account) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (account.firstName.toLowerCase().includes(queryLowered) ||
          account.lastName.toLowerCase().includes(queryLowered) ||
          account.userName.toLowerCase().includes(queryLowered) ||
          account.email.toLowerCase().includes(queryLowered)) &&
        account.companyId.toString() ===
          (companyId.toString() || account.companyId.toString())
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "asc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
  /* eslint-enable  */
});

mock.onPost("/api/user-accounts").reply((config) => {
  // Get event from post data
  const account = JSON.parse(config.data);

  const { length } = accounts;
  let lastIndex = 0;
  if (length) {
    lastIndex = accounts[length - 1].id;
  }

  account.id = lastIndex + 1;
  account.enabled = true;
  account.onboardingStatus = "CREATED";
  account.authServerId = "5d0d46a9-838a-4267-8f3e-8975113d854e";
  account.creationDate = "2021-03-11T13:49:43.359663Z";
  account.lastUpdated = "2021-04-11T13:49:43.560862Z";

  accounts.push(account);

  return [201, { account }];
});

// ------------------------------------------------
// PUT: Edit user account
// ------------------------------------------------
mock.onPut("/api/user-accounts").reply((config) => {
  // Get event from post data
  const account = JSON.parse(config.data);

  accounts = accounts.map((item) => {
    if (item.id === account.id) {
      return account;
    }
    return item;
  });

  return [201, account];
});

// ------------------------------------------------
// GET: Return invitations
// ------------------------------------------------
mock.onGet("/api/invitations").reply((config) => {
  /* eslint-disable */
  const {
    q = "",
    perPage = 10,
    page = 0,
    sort = "id,desc",
    companyId = "",
  } = config.params;
  const queryLowered = q.toLowerCase();
  let filteredData;
  try {
    filteredData = data.invitations.content.filter(
      (invitation) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        invitation.companyId.toString() === companyId.toString()
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  let pendingAccounts;
  try {
    pendingAccounts = filteredData.filter(
      (invitation) => invitation.status === "SENT"
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  let joinRequests;
  try {
    joinRequests = filteredData.filter(
      (invitation) => invitation.status === "REQUESTED"
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "asc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
      totalPending: pendingAccounts.length,
      totalJoinRequests: joinRequests.length,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// GET: Return Single Invitation
// ------------------------------------------------
mock.onGet(/\/api\/invitations\/\d+/).reply((config) => {
  // Get event id from URL
  const invitationId = config.url.substring(config.url.lastIndexOf("/") + 1);

  const invitationIndex = data.invitations.content.findIndex(
    (c) => c.id.toString() === invitationId.toString()
  );
  const invitation = data.invitations.content[invitationIndex];

  if (invitation) return [200, invitation];

  return [404];
});

// ------------------------------------------------
// POST: Accept Invitation
// ------------------------------------------------
mock.onPost(/\/api\/invitations\/\d+\/accept/).reply((config) => {
  // Get invitation id from URL
  const tmp = config.url.substring("/api/invitations/".length);
  const invitationId = tmp.substring(0, tmp.lastIndexOf("/accept"));

  const invitationIndex = data.invitations.content.findIndex(
    (c) => c.id.toString() === invitationId.toString()
  );
  const invitation = data.invitations.content[invitationIndex];

  if (invitation) {
    invitation.status = "SENT";
    return [200, invitation];
  }

  return [404];
});

// ------------------------------------------------
// POST: Reject Invitation
// ------------------------------------------------
mock.onPost(/\/api\/invitations\/\d+\/reject/).reply((config) => {
  // Get invitation id from URL
  const tmp = config.url.substring("/api/invitations/".length);
  const invitationId = tmp.substring(0, tmp.lastIndexOf("/reject"));

  const invitationIndex = data.invitations.content.findIndex(
    (c) => c.id.toString() === invitationId.toString()
  );
  const invitation = data.invitations.content[invitationIndex];

  if (invitation) {
    invitation.status = "REJECTED";
    return [200, invitation];
  }

  return [404];
});

// ------------------------------------------------
// Post: Onboarding token
// ------------------------------------------------
mock.onPost(/\/api\/user-accounts\/onboarding-token/).reply(() => {
  const response = {};
  response.accessToken = "_act-b8ebfb63-5f24-4b89-9c08-5bbabeec986e";
  response.email = "matthieu.capelle@finflx.com";
  response.onboardingSdkUri = "https://test-api.sumsub.com";

  return [201, response];
});

// ------------------------------------------------
// POST: Force password reset
// ------------------------------------------------
mock
  .onPost(/\/api\/user-accounts\/\d+\/force-password-reset/)
  .reply(() => [201]);

// ------------------------------------------------
// GET: Financial Authorities
// ------------------------------------------------
mock.onGet("/api/company-roles").reply(() => [200, data.companyRoles]);

// ------------------------------------------------
// GET: Financial Authorities
// ------------------------------------------------
mock
  .onGet("/api/reference-data/financial-authorities")
  .reply(() => [200, data.financialAuthorities]);

// ------------------------------------------------
// GET: Return Business Activities
// ------------------------------------------------
mock.onGet(/\/api\/reference-data\/business-activities?.*/).reply((config) => {
  /// Get event id from URL
  const code = config.url.substring(config.url.lastIndexOf("/") + 1);

  let object = [];
  if (code === "FA-AE-1") {
    object = data.activities.slice(0, 8);
  } else if (code === "FA-AE-2") {
    object = data.activities.slice(2, 5);
  } else {
    object = data.activities.slice(2, 4);
  }
  if (object.length > 0) return [200, object];

  return [404];
});

// ------------------------------------------------
// POST: Add new company
// ------------------------------------------------
mock.onPost("/api/companies").reply((config) => {
  // Get event from post data
  const company = JSON.parse(config.data);

  const { length } = data.companies;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.companies[length - 1].id;
  }
  if (company.id === undefined) {
    company.creationDate = company.licenseExpiryDate;
    company.lastUpdated = company.licenseExpiryDate;
  }
  company.id = lastIndex + 1;

  data.companies.push(company);

  return [201, { company }];
});

// ------------------------------------------------
// PUT: Edit company
// ------------------------------------------------
mock.onPut("/api/companies").reply((config) => {
  // Get event from post data
  const company = JSON.parse(config.data);

  data.companies = data.companies.map((item) => {
    if (item.id === company.id) {
      return company;
    }
    return item;
  });

  return [201, { company }];
});
// ------------------------------------------------
// POST: ReSend invites
// ------------------------------------------------
mock.onPost(/\/api\/companies\/\d+\/resend-invite/).reply(() => [201]);

// ------------------------------------------------
// POST: Send invites
// ------------------------------------------------
mock.onPost(/\/api\/companies\/\d+\/invite/).reply(() => [201]);

// ------------------------------------------------
// POST: Enable company
// ------------------------------------------------
mock.onPost(/\/api\/companies\/\d+\/enable/).reply((config) => {
  // Get event from post data
  const companyId = config.url
    .substring(config.url.lastIndexOf("/") - 1)
    .split("/")[0];

  data.companies = data.companies.map((item) => {
    if (item.id.toString() === companyId.toString()) {
      const updatedItem = item;
      updatedItem.enabled = true;
      return updatedItem;
    }
    return item;
  });

  return [201];
});

// ------------------------------------------------
// POST: Disable company
// ------------------------------------------------
mock.onPost(/\/api\/companies\/\d+\/disable/).reply((config) => {
  // Get event from post data
  const companyId = config.url
    .substring(config.url.lastIndexOf("/") - 1)
    .split("/")[0];

  data.companies = data.companies.map((item) => {
    if (item.id.toString() === companyId.toString()) {
      const updatedItem = item;
      updatedItem.enabled = false;
      return updatedItem;
    }
    return item;
  });

  return [201];
});
