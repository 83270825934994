/* eslint-disable */
export const data = {
  emptyDashboard: {
    overview: null,
    performance_charts: null,
    deposit_charts: null,
    allocation: null,
    categories: null,
  },
  savingsDashboard: {
    overview: {
      balance: {
        amount: 430400,
        currency_code: "AED",
      },
      net_deposit: {
        amount: 410000,
        currency_code: "AED",
      },
      performance: 0.02,
    },
    performance_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Evolution",
          data: [0, 0, 0, 0, 0, 6000, 4220, 4304],
        },
      ],
    },
    deposit_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Deposit",
          data: [0, 0, 0, 0, 0, 5000, 2100, 0],
        },
        {
          name: "Withdrawal",
          data: [0, 0, 0, 0, 0, 0, 4000, 0],
        },
      ],
    },
    allocation: {
      labels: ["Savings", "Investments"],
      series: [
        {
          name: "Allocation",
          data: [4304, 0],
        },
      ],
    },
    categories: [
      {
        overview: {
          balance: {
            amount: 430400,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 410000,
            currency_code: "AED",
          },
          performance: 0.02,
        },
        category: "savings",
        investment_accounts: [
          {
            overview: {
              balance: {
                amount: 206000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 200000,
                currency_code: "AED",
              },
              performance: 0.02,
            },
            performance_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Evolution",
                  data: [1000, 2020, 2060],
                },
              ],
            },
            deposit_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Deposit",
                  data: [1000, 1000, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0],
                },
              ],
            },
            account_id: "d6ebb76b-c82e-4ed1-8294-c4e9605095be",
            label: "Holiday 2021",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "savings",
            icon: "string",
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-01-30T08:30:00Z",
            last_updated_date: "2021-02-18T17:44:00Z",
          },
          {
            overview: {
              balance: {
                amount: 224400,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 210000,
                currency_code: "AED",
              },
              performance: 0.02,
            },
            performance_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Evolution",
                  data: [5000, 2200, 2244],
                },
              ],
            },
            deposit_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Deposit",
                  data: [1000, 1100, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 4000, 0],
                },
              ],
            },
            account_id: "08a73b13-10da-47a7-89df-0c29aff23874",
            label: "Emergency Fund",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "savings",
            icon: "string",
            goal: {
              target_date: "2022-09-30T00:00:00Z",
              target_amount: {
                amount: 2500000,
                currency_code: "AED",
              },
            },
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-03-30T08:30:00Z",
            last_updated_date: "2021-06-18T17:44:00Z",
          },
        ],
      },
    ],
  },
  investmentsDashboard: {
    overview: {
      balance: {
        amount: 4310000,
        currency_code: "AED",
      },
      net_deposit: {
        amount: 4000000,
        currency_code: "AED",
      },
      performance: 0.04,
    },
    performance_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Evolution",
          data: [9000, 12000, 15000, 28000, 31100, 34900, 38500, 43100],
        },
      ],
    },
    deposit_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Deposit",
          data: [9000, 3000, 3000, 13000, 3000, 3000, 3000, 3000],
        },
        {
          name: "Withdrawal",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    },
    allocation: {
      labels: ["Savings", "Investments"],
      series: [
        {
          name: "Allocation",
          data: [0, 43100],
        },
      ],
    },
    categories: [
      {
        overview: {
          balance: {
            amount: 4310000,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 4000000,
            currency_code: "AED",
          },
          performance: 0.04,
        },
        category: "investments",
        investment_accounts: [
          {
            overview: {
              balance: {
                amount: 1230000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 1000000,
                currency_code: "AED",
              },
              performance: 0.05,
            },
            performance_charts: {
              labels: [
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Evolution",
                  data: [10000, 10100, 10900, 11500, 12300],
                },
              ],
            },
            deposit_charts: {
              labels: [
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Deposit",
                  data: [10000, 0, 0, 0, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0, 0, 0],
                },
              ],
            },
            account_id: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
            label: "Ryan's College",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "investments",
            icon: "string",
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-01-30T08:30:00Z",
            last_updated_date: "2021-02-18T17:44:00Z",
          },
          {
            overview: {
              balance: {
                amount: 3080000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 3000000,
                currency_code: "AED",
              },
              performance: 0.03,
            },
            performance_charts: {
              labels: [
                "Jan 2021",
                "Feb 2021",
                "Mar 2021",
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Evolution",
                  data: [9000, 12000, 15000, 18000, 21000, 24000, 27000, 30800],
                },
              ],
            },
            deposit_charts: {
              labels: [
                "Jan 2021",
                "Feb 2021",
                "Mar 2021",
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Deposit",
                  data: [9000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0, 0, 0, 0, 0, 0],
                },
              ],
            },
            account_id: "2ae08f77-512b-45ef-87d3-40aca2db0bed",
            label: "Retirement Plan",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "investments",
            icon: "string",
            goal: {
              target_date: "2040-12-31T00:00:00Z",
              target_amount: {
                amount: 25000000,
                currency_code: "AED",
              },
            },
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-03-30T08:30:00Z",
            last_updated_date: "2021-06-18T17:44:00Z",
          },
        ],
      },
    ],
  },
  allDashboard: {
    overview: {
      balance: {
        amount: 4740400,
        currency_code: "AED",
      },
      net_deposit: {
        amount: 4410000,
        currency_code: "AED",
      },
      performance: 0.04,
    },
    performance_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Evolution",
          data: [9000, 12000, 15000, 28000, 31100, 40900, 42720, 47404],
        },
      ],
    },
    deposit_charts: {
      labels: [
        "Jan 2021",
        "Feb 2021",
        "Mar 2021",
        "Apr 2021",
        "May 2021",
        "Jun 2021",
        "Jul 2021",
        "Aug 2021",
      ],
      series: [
        {
          name: "Deposit",
          data: [9000, 3000, 3000, 13000, 3000, 5000, 5100, 3000],
        },
        {
          name: "Withdrawal",
          data: [0, 0, 0, 0, 0, 0, 4000, 0],
        },
      ],
    },
    allocation: {
      labels: ["Savings", "Investments"],
      series: [
        {
          name: "Allocation",
          data: [4304, 43100],
        },
      ],
    },
    categories: [
      {
        overview: {
          balance: {
            amount: 4310000,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 4000000,
            currency_code: "AED",
          },
          performance: 0.04,
        },
        category: "investments",
        investment_accounts: [
          {
            overview: {
              balance: {
                amount: 1230000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 1000000,
                currency_code: "AED",
              },
              performance: 0.05,
            },
            performance_charts: {
              labels: [
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Evolution",
                  data: [10000, 10100, 10900, 11500, 12300],
                },
              ],
            },
            deposit_charts: {
              labels: [
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Deposit",
                  data: [10000, 0, 0, 0, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0, 0, 0],
                },
              ],
            },
            account_id: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
            label: "Ryan's College",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "investments",
            icon: "string",
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-01-30T08:30:00Z",
            last_updated_date: "2021-02-18T17:44:00Z",
          },
          {
            overview: {
              balance: {
                amount: 3080000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 3000000,
                currency_code: "AED",
              },
              performance: 0.03,
            },
            performance_charts: {
              labels: [
                "Jan 2021",
                "Feb 2021",
                "Mar 2021",
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Evolution",
                  data: [9000, 12000, 15000, 18000, 21000, 24000, 27000, 30800],
                },
              ],
            },
            deposit_charts: {
              labels: [
                "Jan 2021",
                "Feb 2021",
                "Mar 2021",
                "Apr 2021",
                "May 2021",
                "Jun 2021",
                "Jul 2021",
                "Aug 2021",
              ],
              series: [
                {
                  name: "Deposit",
                  data: [9000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0, 0, 0, 0, 0, 0],
                },
              ],
            },
            account_id: "2ae08f77-512b-45ef-87d3-40aca2db0bed",
            label: "Retirement Plan",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "investments",
            icon: "string",
            goal: {
              target_date: "2040-12-31T00:00:00Z",
              target_amount: {
                amount: 25000000,
                currency_code: "AED",
              },
            },
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-03-30T08:30:00Z",
            last_updated_date: "2021-06-18T17:44:00Z",
          },
        ],
      },
      {
        overview: {
          balance: {
            amount: 430400,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 410000,
            currency_code: "AED",
          },
          performance: 0.02,
        },
        category: "savings",
        investment_accounts: [
          {
            overview: {
              balance: {
                amount: 206000,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 200000,
                currency_code: "AED",
              },
              performance: 0.02,
            },
            performance_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Evolution",
                  data: [1000, 2020, 2060],
                },
              ],
            },
            deposit_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Deposit",
                  data: [1000, 1000, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 0, 0],
                },
              ],
            },
            account_id: "d6ebb76b-c82e-4ed1-8294-c4e9605095be",
            label: "Holiday 2021",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "savings",
            icon: "string",
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-01-30T08:30:00Z",
            last_updated_date: "2021-02-18T17:44:00Z",
          },
          {
            overview: {
              balance: {
                amount: 224400,
                currency_code: "AED",
              },
              net_deposit: {
                amount: 210000,
                currency_code: "AED",
              },
              performance: 0.02,
            },
            performance_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Evolution",
                  data: [5000, 2200, 2244],
                },
              ],
            },
            deposit_charts: {
              labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
              series: [
                {
                  name: "Deposit",
                  data: [1000, 1100, 0],
                },
                {
                  name: "Withdrawal",
                  data: [0, 4000, 0],
                },
              ],
            },
            account_id: "08a73b13-10da-47a7-89df-0c29aff23874",
            label: "Emergency Fund",
            investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            category: "savings",
            icon: "string",
            goal: {
              target_date: "2022-09-30T00:00:00Z",
              target_amount: {
                amount: 2500000,
                currency_code: "AED",
              },
            },
            status: "SUCCESS",
            status_detail: "Investment Accounts created",
            creation_date: "2021-03-30T08:30:00Z",
            last_updated_date: "2021-06-18T17:44:00Z",
          },
        ],
      },
    ],
  },
  emptySavingsAccount: {
    overview: null,
    category: null,
    investment_accounts: null,
  },
  savingsAccount: {
    overview: {
      balance: {
        amount: 430400,
        currency_code: "AED",
      },
      net_deposit: {
        amount: 410000,
        currency_code: "AED",
      },
      performance: 0.02,
    },
    category: "savings",
    investment_accounts: [
      {
        overview: {
          balance: {
            amount: 206000,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 200000,
            currency_code: "AED",
          },
          performance: 0.02,
        },
        performance_charts: {
          labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Evolution",
              data: [1000, 2020, 2060],
            },
          ],
        },
        deposit_charts: {
          labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Deposit",
              data: [1000, 1000, 0],
            },
            {
              name: "Withdrawal",
              data: [0, 0, 0],
            },
          ],
        },
        account_id: "d6ebb76b-c82e-4ed1-8294-c4e9605095be",
        label: "Holiday 2021",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "savings",
        icon: "string",
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-01-30T08:30:00Z",
        last_updated_date: "2021-02-18T17:44:00Z",
      },
      {
        overview: {
          balance: {
            amount: 224400,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 210000,
            currency_code: "AED",
          },
          performance: 0.02,
        },
        performance_charts: {
          labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Evolution",
              data: [5000, 2200, 2244],
            },
          ],
        },
        deposit_charts: {
          labels: ["Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Deposit",
              data: [1000, 1100, 0],
            },
            {
              name: "Withdrawal",
              data: [0, 4000, 0],
            },
          ],
        },
        account_id: "08a73b13-10da-47a7-89df-0c29aff23874",
        label: "Emergency Fund",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "savings",
        icon: "string",
        goal: {
          target_date: "2022-09-30T00:00:00Z",
          target_amount: {
            amount: 2500000,
            currency_code: "AED",
          },
        },
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-03-30T08:30:00Z",
        last_updated_date: "2021-06-18T17:44:00Z",
      },
    ],
  },
  investmentAccount: {
    overview: {
      balance: {
        amount: 4310000,
        currency_code: "AED",
      },
      net_deposit: {
        amount: 4000000,
        currency_code: "AED",
      },
      performance: 0.04,
    },
    category: "investments",
    investment_accounts: [
      {
        overview: {
          balance: {
            amount: 1230000,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 1000000,
            currency_code: "AED",
          },
          performance: 0.05,
        },
        performance_charts: {
          labels: ["Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Evolution",
              data: [10000, 10100, 10900, 11500, 12300],
            },
          ],
        },
        deposit_charts: {
          labels: ["Apr 2021", "May 2021", "Jun 2021", "Jul 2021", "Aug 2021"],
          series: [
            {
              name: "Deposit",
              data: [10000, 0, 0, 0, 0],
            },
            {
              name: "Withdrawal",
              data: [0, 0, 0, 0, 0],
            },
          ],
        },
        account_id: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
        label: "Ryan's College",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "investments",
        icon: "string",
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-01-30T08:30:00Z",
        last_updated_date: "2021-02-18T17:44:00Z",
      },
      {
        overview: {
          balance: {
            amount: 3080000,
            currency_code: "AED",
          },
          net_deposit: {
            amount: 3000000,
            currency_code: "AED",
          },
          performance: 0.03,
        },
        performance_charts: {
          labels: [
            "Jan 2021",
            "Feb 2021",
            "Mar 2021",
            "Apr 2021",
            "May 2021",
            "Jun 2021",
            "Jul 2021",
            "Aug 2021",
          ],
          series: [
            {
              name: "Evolution",
              data: [9000, 12000, 15000, 18000, 21000, 24000, 27000, 30800],
            },
          ],
        },
        deposit_charts: {
          labels: [
            "Jan 2021",
            "Feb 2021",
            "Mar 2021",
            "Apr 2021",
            "May 2021",
            "Jun 2021",
            "Jul 2021",
            "Aug 2021",
          ],
          series: [
            {
              name: "Deposit",
              data: [9000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
            },
            {
              name: "Withdrawal",
              data: [0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        account_id: "2ae08f77-512b-45ef-87d3-40aca2db0bed",
        label: "Retirement Plan",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "investments",
        icon: "string",
        goal: {
          target_date: "2040-12-31T00:00:00Z",
          target_amount: {
            amount: 25000000,
            currency_code: "AED",
          },
        },
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-03-30T08:30:00Z",
        last_updated_date: "2021-06-18T17:44:00Z",
      },
    ],
  },
  emptyInvestmentAccount: {
    overview: null,
    category: null,
    investment_accounts: null,
  },
  defaultQuestionnaire: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Risk Assessment Questionnaire",
    title: "Risk Assessment Questionnaire",
    description: "Risk Assessment Questionnaire",
    questions: [
      {
        number: 1,
        label: "I plan to keep investing my money for",
        category: "Time Horizon",
        choices: [
          {
            code: "a",
            label: "Less than 3 years",
          },
          {
            code: "b",
            label: "3 -5 years",
          },
          {
            code: "c",
            label: "6 – 10 years",
          },
          {
            code: "d",
            label: "11 + years",
          },
        ],
      },
      {
        number: 2,
        label:
          "Once I begin withdrawing funds from my investments, I plan to spend it over the period of",
        category: "Time Horizon",
        choices: [
          {
            code: "a",
            label: "Less than 2 year",
          },
          {
            code: "b",
            label: "2 – 5 years",
          },
          {
            code: "c",
            label: "6 -10 years",
          },
          {
            code: "d",
            label: "11+ years",
          },
        ],
      },
      {
        number: 3,
        label:
          "When it comes to investing in stocks and bonds, my experience level is",
        category: "Risk Tolerance",
        choices: [
          {
            code: "a",
            label: "No experience",
          },
          {
            code: "b",
            label: "Limited experience",
          },
          {
            code: "c",
            label: "Good experience",
          },
          {
            code: "d",
            label: "High experience",
          },
        ],
      },
      {
        number: 4,
        label: "When it comes to investments, I prefer investments with",
        category: "Risk Tolerance",
        choices: [
          {
            code: "a",
            label: "Little or no ups and downs in value, lower returns",
          },
          {
            code: "b",
            label: "Moderate ups and downs in value, slightly higher returns ",
          },
          {
            code: "c",
            label: "Extreme ups and downs in value, high potential returns",
          },
        ],
      },
      {
        number: 5,
        label:
          "My current and future income sources (salary, assets and rental properties) is",
        category: "Risk Tolerance",
        choices: [
          {
            code: "a",
            label: "Very unstable",
          },
          {
            code: "b",
            label: "Stable",
          },
          {
            code: "c",
            label: "Very stable",
          },
        ],
      },
      {
        number: 6,
        label:
          "Consider this scenario: \nDuring global financial crisis, between September – November 2008, the stock market lost more than 31% of its value. If you owned stocks during that period, you would",
        category: "Risk Tolerance",
        choices: [
          {
            code: "a",
            label: "Sell all your shares",
          },
          {
            code: "b",
            label: "Sell some of your shares",
          },
          {
            code: "c",
            label: "Hold on to all shares owned (Do Nothing) ",
          },
          {
            code: "d",
            label: "Buy more of these shares",
          },
        ],
      },
      {
        number: 7,
        label:
          "The chart below shows multiple investment portfolios with a hypothetical best- and worst-case scenario of returns in ONE year. \nWhich portfolio would you choose?\n",
        category: "Risk Tolerance",
        image: "/inv_port_best_wort.png",
        choices: [
          {
            code: "a",
            label: "a",
          },
          {
            code: "b",
            label: "b",
          },
          {
            code: "c",
            label: "c",
          },
          {
            code: "d",
            label: "d",
          },
          {
            code: "e",
            label: "e",
          },
        ],
      },
    ],
  },
  helperForecast2: {
    forecast: {
      labels: ["10/21", "11/21"],
      series: [
        {
          data: [12.008333333333113, 14.018340277777213],
          name: "Worst Case",
        },
        {
          data: [12.033333333333381, 14.073444444444583],
          name: "Average",
        },
        {
          data: [12.016666666666714, 14.03669444444456],
          name: "Best Case",
        },
      ],
    },
    initial_amount: {
      amount: 1000,
      currency_code: "AED",
    },
    monthly_amount: {
      amount: 20000,
      currency_code: "AED",
    },
  },
  helperForecast: {
    monthly_amount: {
      amount: 20000,
      currency_code: "AED",
    },
    forecast: {
      labels: [
        "Aug 21",
        "Sep 21",
        "Oct 21",
        "Nov 21",
        "Dec 21",
        "Jan 22",
        "Feb 22",
        "Mar 22",
        "Apr 22",
        "May 22",
        "Jun 22",
        "Jul 22",
        "Aug 22",
        "Sep 22",
        "Oct 22",
        "Nov 22",
        "Dec 22",
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
        "Jan 24",
        "Feb 24",
        "Mar 24",
        "Apr 24",
        "May 24",
        "Jun 24",
        "Jul 24",
        "Aug 24",
        "Sep 24",
        "Oct 24",
        "Nov 24",
        "Dec 24",
      ],
      series: [
        {
          name: "Worst Case",
          data: [
            1203, 1406, 1609, 1813, 2018, 2223, 2428, 2634, 2841, 3048, 3256,
            3464, 3672, 3882, 4091, 4301, 4512, 4723, 4935, 5148, 5361, 5574,
            5788, 6002, 6217, 6433, 6649, 6866, 7083, 7300, 7519, 7737, 7957,
            8177, 8397, 8618, 8840, 9062, 9284, 9508, 9731,
          ],
        },
        {
          name: "Average",
          data: [
            1203, 1407, 1612, 1817, 2023, 2230, 2438, 2646, 2855, 3064, 3274,
            3485, 3697, 3909, 4122, 4336, 4550, 4766, 4981, 5198, 5415, 5633,
            5852, 6072, 6292, 6513, 6735, 6957, 7180, 7404, 7629, 7854, 8081,
            8307, 8535, 8764, 8993, 9223, 9454, 9685, 9917,
          ],
        },
        {
          name: "Best Case",
          data: [
            1204, 1409, 1615, 1822, 2029, 2238, 2447, 2657, 2868, 3080, 3293,
            3507, 3722, 3937, 4153, 4371, 4589, 4808, 5028, 5249, 5471, 5694,
            5917, 6142, 6368, 6594, 6822, 7050, 7280, 7510, 7741, 7973, 8207,
            8441, 8676, 8912, 9149, 9387, 9627, 9867, 10108,
          ],
        },
      ],
    },
  },
  assessedRiskLevel1: {
    id: "ff45827a-9c3d-4b5b-8bed-06b2a85b683f",
    result: {
      id: "28d33260-a9bf-44f9-8a64-dc84115c4b1f",
      name: "Income",
      rank: 1,
      description: "1. Income Risk Level",
    },
    answers: {
      questionnaire_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      answers: [
        {
          question_number: 1,
          choice_code: "b",
        },
        {
          question_number: 2,
          choice_code: "a",
        },
        {
          question_number: 3,
          choice_code: "c",
        },
        {
          question_number: 4,
          choice_code: "b",
        },
        {
          question_number: 5,
          choice_code: "c",
        },
        {
          question_number: 6,
          choice_code: "c",
        },
        {
          question_number: 7,
          choice_code: "b",
        },
      ],
    },
  },
  assessedRiskLevel2: {
    id: "ff45827a-9c3d-4b5b-8bed-06b2a85b683f",
    result: {
      id: "dcd3b4a7-0eff-476f-a48f-7c95f0e7a954",
      name: "Conservative",
      rank: 2,
      description: "2. Conservative Risk Level",
    },
    answers: {
      questionnaire_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      answers: [
        {
          question_number: 1,
          choice_code: "b",
        },
        {
          question_number: 2,
          choice_code: "a",
        },
        {
          question_number: 3,
          choice_code: "c",
        },
        {
          question_number: 4,
          choice_code: "b",
        },
        {
          question_number: 5,
          choice_code: "c",
        },
        {
          question_number: 6,
          choice_code: "c",
        },
        {
          question_number: 7,
          choice_code: "b",
        },
      ],
    },
  },
  assessedRiskLevel3: {
    id: "ff45827a-9c3d-4b5b-8bed-06b2a85b683f",
    result: {
      id: "97702bbb-42bd-49e5-b3e1-fe738f89fe8d",
      name: "Growth & Income",
      rank: 3,
      description: "3. Growth & Income Risk Level",
    },
    answers: {
      questionnaire_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      answers: [
        {
          question_number: 1,
          choice_code: "b",
        },
        {
          question_number: 2,
          choice_code: "a",
        },
        {
          question_number: 3,
          choice_code: "c",
        },
        {
          question_number: 4,
          choice_code: "b",
        },
        {
          question_number: 5,
          choice_code: "c",
        },
        {
          question_number: 6,
          choice_code: "c",
        },
        {
          question_number: 7,
          choice_code: "b",
        },
      ],
    },
  },
  assessedRiskLevel4: {
    id: "ff45827a-9c3d-4b5b-8bed-06b2a85b683f",
    result: {
      id: "caa3df4b-94c7-4dc3-a21b-b8c83c902edb",
      name: "Growth",
      rank: 4,
      description: "4. Growth Risk Level",
    },
    answers: {
      questionnaire_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      answers: [
        {
          question_number: 1,
          choice_code: "b",
        },
        {
          question_number: 2,
          choice_code: "a",
        },
        {
          question_number: 3,
          choice_code: "c",
        },
        {
          question_number: 4,
          choice_code: "b",
        },
        {
          question_number: 5,
          choice_code: "c",
        },
        {
          question_number: 6,
          choice_code: "c",
        },
        {
          question_number: 7,
          choice_code: "b",
        },
      ],
    },
  },
  assessedRiskLevel5: {
    id: "ff45827a-9c3d-4b5b-8bed-06b2a85b683f",
    result: {
      id: "3f150447-33dd-4b7b-a485-d27e1b60e4b4",
      name: "Aggressive",
      rank: 5,
      description: "5. Aggressive Risk Level",
    },
    answers: {
      questionnaire_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      answers: [
        {
          question_number: 1,
          choice_code: "b",
        },
        {
          question_number: 2,
          choice_code: "a",
        },
        {
          question_number: 3,
          choice_code: "c",
        },
        {
          question_number: 4,
          choice_code: "b",
        },
        {
          question_number: 5,
          choice_code: "c",
        },
        {
          question_number: 6,
          choice_code: "c",
        },
        {
          question_number: 7,
          choice_code: "b",
        },
      ],
    },
  },
  riskLevels: [
    {
      id: "caa3df4b-94c7-4dc3-a21b-b8c83c902edb",
      name: "Growth",
      rank: 4,
      description: "4. Growth Risk Level",
    },
    {
      id: "3f150447-33dd-4b7b-a485-d27e1b60e4b4",
      name: "Aggressive",
      rank: 5,
      description: "5. Aggressive Risk Level",
    },
    {
      id: "28d33260-a9bf-44f9-8a64-dc84115c4b1f",
      name: "Income",
      rank: 1,
      description: "1. Income Risk Level",
    },
    {
      id: "97702bbb-42bd-49e5-b3e1-fe738f89fe8d",
      name: "Growth & Income",
      rank: 3,
      description: "3. Growth & Income Risk Level",
    },
    {
      id: "dcd3b4a7-0eff-476f-a48f-7c95f0e7a954",
      name: "Conservative",
      rank: 2,
      description: "2. Conservative Risk Level",
    },
  ],
  portfolioSummaryList: {
    pageable: {
      sort: {
        sorted: true,
        unsorted: false,
        empty: false,
      },
      pageNumber: 0,
      pageSize: 100,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: 5,
    totalPages: 1,
    last: false,
    first: true,
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    size: 100,
    number: 0,
    numberOfElements: 5,
    empty: false,
    content: [
      {
        id: "64be550b-d7b1-4000-bac5-e5a2b59a3163",
        name: "Growth portfolio",
        highlight:
          "This portfolio is suitable for the less cautious investors who understand market risks and are willing to tolerate price fluctuations for the sake of higher capital gains in the long run. Usually with a long term investment horizon (5-10 years).",
        description:
          "This portfolio is more concentrated in stocks (70%) than bonds (30%), this offers a higher potential of capital growth in the long run. However, it also has higher potential of market volatility and price fluctuation.",
        category: "Investments",
        type: {
          id: "ee983394-3de4-40eb-8265-8527162a9322",
          name: "Standard",
          highlight:
            "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
          description:
            "Standard funds are funds that are not governed by specific requirements.",
          icon: "/standard.svg",
        },
        risk_level: {
          id: "caa3df4b-94c7-4dc3-a21b-b8c83c902edb",
          name: "Growth",
          rank: 4,
          description: "4. Growth Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0
            },
            {
              period: "1m",
              value: 1.15,
              volume: 0
            },
            {
              period: "3m",
              value: 5.14,
              volume: 0
            },
            {
              period: "YTD",
              value: 10.12,
              volume: 0
            },
            {
              period: "1y",
              value: 21.69,
              volume: 0
            },
            {
              period: "3y",
              value: 12.98,
              volume: 0
            },
            {
              period: "5y",
              value: 12.75,
              volume: 0
            }
          ],
          forecast: {
            worst_case: 1,
            best_case: 1,
            average: 1
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21"
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0,
                  10115.088241219615,
                  10428.674402445467,
                  10635.022804990189,
                  10776.06206801087,
                  10718.47539736325,
                  10462.01349682982,
                  10558.525327086947,
                  10733.721858755254,
                  10487.860240137774,
                  10541.585598288359,
                  10682.642182009198,
                  10845.77650851394,
                  11187.133976227746,
                  11488.120603056723,
                  11295.272736739795,
                  11452.925222747514,
                  11266.878472479648,
                  11141.47581996265,
                  11476.769242478935,
                  11567.575591999173,
                  11898.867574883156,
                  11842.129265939235,
                  11879.879170762822,
                  11644.445421731909,
                  12047.174722978827,
                  12084.912682733975,
                  12368.156313880481,
                  12393.454591553049,
                  12553.352276457235,
                  12638.014000975187,
                  12327.305579892596,
                  12334.273236481562,
                  12367.644848561005,
                  12629.083854395427,
                  12873.546273561218,
                  13233.740274617743,
                  13499.728945890776,
                  13601.1745480302,
                  13789.612678870495,
                  13674.458183213028,
                  13782.939326314148,
                  14220.702465527314,
                  14591.668799607616,
                  14381.374491373086,
                  14028.463334817061,
                  14228.314883279236,
                  14664.665476259577,
                  14900.254210200248,
                  14448.45996211979,
                  14372.597369233257,
                  13789.305863783062,
                  13530.47672936034,
                  13446.804295064525,
                  13937.193146226857,
                  14097.870864500783,
                  13240.271696176696,
                  13194.424692912005,
                  13361.03510293883,
                  12399.71659345237,
                  10778.080520030253,
                  10301.480005141051,
                  10660.375864839496,
                  10036.194358141438,
                  9337.677348491557,
                  9842.090223970577,
                  10527.843408127323,
                  10922.412297411543,
                  10964.579544054279,
                  11564.406102575496,
                  11896.656065524483,
                  12272.404443925236,
                  12063.207944343034,
                  12573.859691743939,
                  12741.92934017056,
                  12485.289181984881,
                  12784.292972930929,
                  13343.698354603383,
                  13586.068657900685,
                  12880.1942133083,
                  12441.165195266927,
                  13056.288145147095,
                  12694.617662080738,
                  13491.305618044145,
                  13868.67233643464,
                  13881.811672985714,
                  14523.373122975845,
                  14730.78771147796,
                  15131.74896775118,
                  15167.935266943972,
                  15551.145611585256,
                  15484.810101936228,
                  15264.942352374155,
                  15089.824881834105,
                  14505.444225876989,
                  13779.241873880597,
                  14840.076232529336,
                  14786.58919599864,
                  14948.096245069324,
                  15508.429265664628,
                  15968.824804407628,
                  16291.991492506322,
                  16258.510563659747,
                  15581.872839637068,
                  16023.413280204226,
                  16186.253534603034,
                  16496.484046761652,
                  16802.76960907331,
                  16582.322555413448,
                  16686.972776855233,
                  16829.965313358854,
                  17456.86160612122,
                  17648.195687530202,
                  18163.475397106573,
                  18425.049288964114,
                  18663.420277708425,
                  18389.26667259128,
                  19194.056278136337,
                  18713.871184374628,
                  19316.228913666662,
                  19981.659888800037,
                  20384.02508340772,
                  20788.224345830444,
                  20347.198363006573,
                  21126.554650967788,
                  21203.18313021238,
                  21252.011148980117,
                  21654.918103129858,
                  22093.934236031775,
                  21736.896712213977,
                  22495.51391959938,
                  22095.598889939185,
                  22621.41161087934,
                  23093.831913376653,
                  23094.083468462206,
                  22696.968683843083,
                  23668.23435829481,
                  23469.226252174638,
                  23566.970539337322,
                  23786.677652889055,
                  23417.81750284672,
                  23775.108601970856,
                  22610.44557088115,
                  22132.93414752309,
                  23495.67148339135,
                  23587.78955425885,
                  23182.24141774257,
                  22198.33602313631,
                  22240.82128424245,
                  23508.446438370942,
                  23642.733348900743,
                  23965.24660319267,
                  24115.331041801866,
                  24896.052411515266,
                  24925.44370548554,
                  24967.40210388536,
                  24491.75703046714,
                  25227.249655724696,
                  25641.26041051995,
                  26034.218308695366,
                  26842.02812791123,
                  26851.236160848617,
                  27128.796712103554,
                  27386.52079346672,
                  27596.07271575262,
                  28035.08823730785,
                  28118.115076416983,
                  28646.672307712644,
                  29160.42136279756,
                  29878.582116717513,
                  30189.826512395986,
                  31437.141379060336,
                  30397.300723508808,
                  29935.233671737456,
                  29998.20954658703,
                  30709.558876089824,
                  30894.78280049662,
                  31748.01209669731,
                  32690.609813196916,
                  32712.57939674663,
                  30635.705047912048,
                  31189.23616368282,
                  28900.680824567055,
                  30969.013056337248,
                  31876.902227371887,
                  32365.797324140825,
                  33413.61421819138,
                  31708.63020645968,
                  33624.89303242833,
                  34031.57497845789,
                  33593.20690695201,
                  34054.33459377356,
                  34663.76050878428,
                  35759.302136760634,
                  36599.90825222904,
                  36696.34951749562,
                  34316.82325888777,
                  30338.73945557954,
                  33654.25533584294,
                  35188.75628588468,
                  35899.082367116425,
                  37694.46723846905,
                  39887.271731569665,
                  38685.09163589447,
                  38015.34094809818,
                  41863.23881070028,
                  43540.687840907674,
                  43370.1978219816,
                  44449.31706331257,
                  45784.492607889064,
                  47836.33711309824,
                  48039.47698899683,
                  49133.250375402706,
                  49949.34441152989,
                  51191.33325480411,
                  49121.1190988209,
                  51763.647804397224
                ]
              }
            ]
          }
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "VT",
              label: "Vanguard Total World Stock",
            },
            {
              ticker: "VTI",
              label: "Vanguard Total Stock Market",
            },
            {
              ticker: "IXUS",
              label: "iShares Core MSCI Total Intl Stk",
            },
            {
              ticker: "IEMG",
              label: "iShares Core MSCI Emerging Markets",
            },
            {
              ticker: "EES",
              label: "WisdomTree US SmallCap Earnings",
            },
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "IVE",
              label: "iShares S&P 500 Value",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [
                0.22, 0.11, 0.18, 0.05, 0.03, 0.06, 0.07, 0.2, 0.03, 0.03, 0.02,
              ],
            },
          ],
        },
        sectors_distribution: null,
      },
      {
        id: "63a85f16-6fcb-4dc2-93e5-c1b59ff8b217",
        name: "Aggressive portfolio",
        highlight:
          "This portfolio is suitable only for seasoned investors who have a clear understanding of investment risks and have a much longer time horizon (10+ years).",
        description:
          "This portfolio has a very high concentration of stocks (80%) than bonds (20%), offering the highest potential of capturing overall market growth. However, it also has the highest exposure to risks of market volatility.",
        category: "Investments",
        type: {
          id: "ee983394-3de4-40eb-8265-8527162a9322",
          name: "Standard",
          highlight:
            "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
          description:
            "Standard funds are funds that are not governed by specific requirements.",
          icon: "/standard.svg",
        },
        risk_level: {
          id: "3f150447-33dd-4b7b-a485-d27e1b60e4b4",
          name: "Aggressive",
          rank: 5,
          description: "5. Aggressive Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0,
            },
            {
              period: "1m",
              value: 1.35,
              volume: 0,
            },
            {
              period: "3m",
              value: 5.62,
              volume: 0,
            },
            {
              period: "YTD",
              value: 11.81,
              volume: 0,
            },
            {
              period: "1y",
              value: 25.13,
              volume: 0,
            },
            {
              period: "3y",
              value: 14.02,
              volume: 0,
            },
            {
              period: "5y",
              value: 14.13,
              volume: 0,
            },
          ],
          forecast: {
            worst_case: 1.0,
            best_case: 1.0,
            average: 1.0,
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21",
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0, 10126.753246642005, 10471.099302222796,
                  10700.546021670345, 10845.143573246918, 10769.276978294378,
                  10517.724304800553, 10633.46003143945, 10823.248866282522,
                  10528.904367574221, 10566.721363451139, 10720.270001899009,
                  10893.809110519807, 11295.028397947817, 11627.131757954578,
                  11399.398246962919, 11585.18041934524, 11387.212190270573,
                  11218.2311665669, 11588.893404586877, 11679.283322798403,
                  12073.857974905039, 11990.991923033615, 12048.589192886444,
                  11794.671212243406, 12249.260274745044, 12275.162233854266,
                  12599.79073329954, 12626.230470525465, 12822.309709806465,
                  12921.341840226625, 12569.27004845645, 12579.672254158617,
                  12596.745819444423, 12871.067615262811, 13134.652774833565,
                  13535.485026026618, 13822.594698887213, 13948.173001715168,
                  14164.657320831737, 14007.125043068634, 14134.272361276562,
                  14625.026644804273, 15063.912700657847, 14829.65237315357,
                  14409.097270838907, 14616.723981045547, 15105.00324835197,
                  15357.563854442742, 14811.086990657539, 14724.54526337676,
                  14018.575411798061, 13725.55951895606, 13627.684899443851,
                  14182.428030403695, 14388.150447457152, 13411.225748200794,
                  13352.046086840748, 13529.646158637603, 12461.613442108937,
                  10647.307232574876, 10052.102148019952, 10347.609346810546,
                  9670.723649907375, 8891.409636313394, 9448.247120906783,
                  10222.696073553745, 10660.786797055653, 10701.309087932916,
                  11363.608059568614, 11720.709540130913, 12127.85792816888,
                  11884.559437411895, 12443.701604056925, 12672.024598316213,
                  12351.973990609182, 12689.889917584876, 13329.340857624216,
                  13587.64017433525, 12759.931344298204, 12223.516501846156,
                  12909.467870412196, 12470.231928274417, 13380.58553464439,
                  13808.7630755372, 13840.811911193035, 14587.70216133705,
                  14826.457576527959, 15278.858737156119, 15324.713411132065,
                  15731.155941954774, 15630.04087949096, 15387.951003778164,
                  15153.116989509235, 14453.443513623948, 13607.130594379998,
                  14816.805148077498, 14762.763653189319, 14918.361085915536,
                  15543.029543340828, 16069.494252969973, 16451.31846092271,
                  16393.410906989022, 15596.522035880207, 16101.57419569911,
                  16257.56752629156, 16612.120602943683, 16956.17771703805,
                  16705.301582307122, 16818.822881710097, 16987.773731842055,
                  17718.175540227166, 17923.65406303691, 18510.32502376362,
                  18787.50622524419, 19105.341073775387, 18826.839741223965,
                  19740.71187355466, 19210.071372330312, 19874.079507796734,
                  20616.251364780695, 21081.651146755456, 21555.920377058144,
                  21017.956570865885, 21900.233993519647, 21991.132541545,
                  22028.519044590797, 22462.347991305392, 22965.399181037894,
                  22563.091404534087, 23403.87518987617, 22960.998593598586,
                  23537.555517632165, 24062.24947906186, 24059.100921032474,
                  23557.343636914273, 24688.67729643896, 24452.398438268952,
                  24571.749125782466, 24833.17521257685, 24436.948188803326,
                  24825.214553116133, 23502.052991342785, 22937.350032029182,
                  24493.149636743572, 24607.60385146133, 24148.62778489534,
                  22995.06929513476, 23022.55271218126, 24450.321126178216,
                  24597.621462080835, 24965.88904633326, 25090.52296161165,
                  25969.326637450187, 26008.267124751226, 26054.93509183638,
                  25531.604171022376, 26435.258949392875, 26902.320759486003,
                  27346.304107137315, 28253.96935534202, 28265.825275557014,
                  28561.07933901238, 28838.83890422869, 29078.77134829,
                  29572.290627805043, 29643.948596566508, 30262.254193537352,
                  30846.97424036963, 31671.39679785393, 32015.349144138727,
                  33468.829983886724, 32305.246341842656, 31760.829165952684,
                  31855.87414475189, 32652.77902304567, 32861.94605620293,
                  33837.77957514385, 34901.14475120007, 34941.490660403295,
                  32583.666323461985, 33197.53417505117, 30533.561513497745,
                  32874.63565966304, 33915.092030485655, 34420.42341377266,
                  35623.07768127265, 33625.537014848946, 35786.84541583756,
                  36246.82144473445, 35672.183769275915, 36215.83875353215,
                  36906.699282908405, 38159.68460068603, 39121.64595173602,
                  39176.9357832228, 36413.71789597694, 31882.11121039545,
                  35623.30065364453, 37357.9250172133, 38150.88885474466,
                  40164.40452041036, 42694.47812336029, 41323.36841746114,
                  40574.06711400846, 44936.99957902902, 46851.59445872466,
                  46678.41018965149, 47955.52584269934, 49514.03673046871,
                  51838.424087121064, 52064.81268359873, 53291.347119331745,
                  54192.08202864807, 55617.29312761987, 53277.81581931488,
                  55986.45922736799,
                ],
              },
            ],
          },
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "VT",
              label: "Vanguard Total World Stock",
            },
            {
              ticker: "VTI",
              label: "Vanguard Total Stock Market",
            },
            {
              ticker: "IXUS",
              label: "iShares Core MSCI Total Intl Stk",
            },
            {
              ticker: "IEMG",
              label: "iShares Core MSCI Emerging Markets",
            },
            {
              ticker: "EES",
              label: "WisdomTree US SmallCap Earnings",
            },
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "IVE",
              label: "iShares S&P 500 Value",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [
                0.26, 0.15, 0.19, 0.06, 0.03, 0.04, 0.08, 0.11, 0.03, 0.03,
                0.02,
              ],
            },
          ],
        },
        sectors_distribution: null,
      },
      {
        id: "450a1008-99e0-4b5d-9718-1bb3a5af0cfe",
        name: "Income portfolio",
        highlight:
          "This portfolio is suitable for cautious investors who are worried about price fluctuation and comfortable with a short to mid term time horizon of investment, usually between 3-5 years.",
        description:
          "This portfolio is more concentrated towards bonds (80%), than stocks (20%), this offers more capital protection and a modest income. However, it also reduces the potential of capital growth in the long run.",
        category: "Investments",
        type: {
          id: "ee983394-3de4-40eb-8265-8527162a9322",
          name: "Standard",
          highlight:
            "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
          description:
            "Standard funds are funds that are not governed by specific requirements.",
          icon: "/standard.svg",
        },
        risk_level: {
          id: "28d33260-a9bf-44f9-8a64-dc84115c4b1f",
          name: "Income",
          rank: 1,
          description: "1. Income Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0
            },
            {
              period: "1m",
              value: 0.08,
              volume: 0
            },
            {
              period: "3m",
              value: 0.36,
              volume: 0
            },
            {
              period: "YTD",
              value: 1.7,
              volume: 0
            },
            {
              period: "1y",
              value: 5.26,
              volume: 0
            },
            {
              period: "3y",
              value: 7.62,
              volume: 0
            },
            {
              period: "5y",
              value: 5.74,
              volume: 0
            }
          ],
          forecast: {
            worst_case: 1,
            best_case: 1,
            average: 1
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21"
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0,
                  10056.770442568532,
                  10216.54785973545,
                  10307.388166930206,
                  10430.650947060782,
                  10464.47176785088,
                  10183.458234186543,
                  10183.838903680247,
                  10286.093735262742,
                  10282.623891981406,
                  10415.903463402767,
                  10494.483107172537,
                  10605.591132885082,
                  10647.633287524417,
                  10793.04035389479,
                  10774.614479981936,
                  10791.630138039578,
                  10665.209696697206,
                  10757.67009776173,
                  10916.15469961417,
                  11009.013238387106,
                  11023.90550042854,
                  11097.778858893713,
                  11036.308743150059,
                  10893.31496766256,
                  11036.725814473359,
                  11133.644833211201,
                  11209.985372850377,
                  11229.556297206225,
                  11208.544015346213,
                  11221.380098663814,
                  11117.492275210967,
                  11107.265349928099,
                  11222.124025580842,
                  11419.14097224341,
                  11567.998527758986,
                  11725.008605185853,
                  11885.36749582969,
                  11866.147471094231,
                  11914.378023900257,
                  12011.099455165167,
                  12026.277179867488,
                  12199.05334676176,
                  12230.41748589309,
                  12139.959795859342,
                  12125.306184338773,
                  12286.26812693874,
                  12462.958991725867,
                  12613.661357648401,
                  12635.290772310611,
                  12612.824806211738,
                  12642.925585861622,
                  12555.043017909997,
                  12542.39916865495,
                  12711.013598709342,
                  12646.477421167532,
                  12385.512273894521,
                  12406.304557117384,
                  12517.983153684601,
                  12090.21347874603,
                  11431.921808937148,
                  11548.347394183156,
                  12224.194612674859,
                  11863.5250072455,
                  11569.00741781578,
                  11811.300874080458,
                  12053.599781835394,
                  12230.531201914979,
                  12280.91338102992,
                  12568.385383231875,
                  12776.356434623,
                  12995.136605687761,
                  12956.419827132428,
                  13224.640890138806,
                  13091.454728118839,
                  13151.852006426103,
                  13256.295619571034,
                  13415.483268689008,
                  13578.191286824087,
                  13481.475598177474,
                  13529.395364778657,
                  13790.400639601974,
                  13816.517608958857,
                  14044.89751067933,
                  14168.220320959423,
                  14086.771856924515,
                  14201.722150622725,
                  14252.422088168492,
                  14396.161549494467,
                  14384.027719620457,
                  14651.05708259689,
                  14758.664879135558,
                  14649.846342829454,
                  14773.334068817638,
                  14765.436487142275,
                  14639.802351482296,
                  14956.429516366643,
                  14905.703705364016,
                  15096.757079667954,
                  15335.413180600954,
                  15465.47187039827,
                  15495.337165098179,
                  15583.980941799498,
                  15508.599147876761,
                  15632.567292115986,
                  15829.667602925752,
                  15918.305843906423,
                  16035.685156513504,
                  15967.400703450427,
                  16027.697211706025,
                  16040.934059429788,
                  16150.277089186962,
                  16270.900333825211,
                  16429.184840318085,
                  16612.789118468023,
                  16453.795523359793,
                  16201.369750233674,
                  16460.77427767028,
                  16232.863194296375,
                  16526.963308760118,
                  16808.74025420758,
                  16895.88084387482,
                  16949.72350675684,
                  16993.377853542264,
                  17258.12125856035,
                  17263.408234393428,
                  17369.432647082016,
                  17617.74561623769,
                  17736.6028627478,
                  17605.931766173722,
                  17953.68711352501,
                  17768.551400443543,
                  18040.675863554556,
                  18251.725107667517,
                  18268.996822650537,
                  18395.054035928915,
                  18565.98440556268,
                  18553.35569551165,
                  18543.042467797703,
                  18554.160246751228,
                  18322.172761458245,
                  18524.536789468344,
                  18152.38028045559,
                  18110.823120694396,
                  18508.255459890184,
                  18488.68423376505,
                  18350.289168322517,
                  18214.655775305,
                  18332.11501940594,
                  18799.059337919694,
                  18868.230838815078,
                  18962.018373152645,
                  19239.34326407811,
                  19529.639230533514,
                  19511.288518727208,
                  19529.673629886463,
                  19292.482425231752,
                  19187.197240265825,
                  19335.92815837626,
                  19473.76413449493,
                  19782.299832709654,
                  19778.237694467814,
                  19967.34234622943,
                  20124.915426019546,
                  20182.54402891468,
                  20349.054495391705,
                  20488.92559066145,
                  20568.74881594009,
                  20727.645819695626,
                  20914.459300702314,
                  21062.195625318353,
                  21278.65685356656,
                  20857.51811883662,
                  20807.195117843876,
                  20709.869046757176,
                  20993.455371856173,
                  21058.91540738919,
                  21299.121225500683,
                  21637.89477118064,
                  21567.961770312806,
                  20895.849197679763,
                  21147.690638875752,
                  20736.24875017697,
                  21440.86577923457,
                  21685.93344929532,
                  22092.598411707633,
                  22366.240101784122,
                  22124.06369428528,
                  22815.091003914506,
                  22955.303377350345,
                  23198.269607084927,
                  23246.771844539508,
                  23449.02661698594,
                  23757.361402393628,
                  23991.203254592874,
                  24293.36145692056,
                  23832.323423166228,
                  22621.828978220954,
                  23808.989973258795,
                  24342.883602956135,
                  24640.007116297733,
                  25344.731855522037,
                  25851.206404245866,
                  25493.65888389592,
                  25221.679009798976,
                  26494.36935433071,
                  26986.101373108228,
                  26829.085471600047,
                  26918.222672883705,
                  27136.71455019422,
                  27825.84365386095,
                  27912.74101493228,
                  28342.70650446845,
                  28735.596526959092,
                  29061.471256212593,
                  28337.576692171137,
                  29032.702869205044
                ]
              }
            ]
          }
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
            {
              ticker: "DWX",
              label: "SPDR S&P International Dividend",
            },
            {
              ticker: "IEI",
              label: "iShares 3-7 Year Treasury Bond",
            },
            {
              ticker: "IGSB",
              label: "iShares 1-5 Year invmt Grd Corp Bd",
            },
            {
              ticker: "EDIV",
              label: "SPDR S&P Emerging Markets Dividend",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [0.12, 0.34, 0.13, 0.16, 0.05, 0.09, 0.04, 0.05, 0.02],
            },
          ],
        },
        sectors_distribution: null,
      },
      {
        id: "95398d1f-abef-4a52-a39f-74e8609411c7",
        name: "Growth & Income portfolio",
        highlight:
          "This portfolio is a typical retirement portfolio, suitable for strategic investors who are willing to tolerate some market risk in search for long term gains, usually with a mid to long term investment time horizon (5-10 years).",
        description:
          "This is the gold standard retirement portfolio, it consists of stocks (60%) and bonds (40%). This offers the potential of capital growth on the long run mixed with some income generating investments. Due to higher concentration of stocks, this portfolio has a higher potential of short term price fluctuations.",
        category: "Investments",
        type: {
          id: "ee983394-3de4-40eb-8265-8527162a9322",
          name: "Standard",
          highlight:
            "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
          description:
            "Standard funds are funds that are not governed by specific requirements.",
          icon: "/standard.svg",
        },
        risk_level: {
          id: "97702bbb-42bd-49e5-b3e1-fe738f89fe8d",
          name: "Growth & Income",
          rank: 3,
          description: "3. Growth & Income Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0
            },
            {
              period: "1m",
              value: 0.95,
              volume: 0
            },
            {
              period: "3m",
              value: 4.36,
              volume: 0
            },
            {
              period: "YTD",
              value: 8.44,
              volume: 0
            },
            {
              period: "1y",
              value: 18.31,
              volume: 0
            },
            {
              period: "3y",
              value: 11.94,
              volume: 0
            },
            {
              period: "5y",
              value: 11.36,
              volume: 0
            }
          ],
          forecast: {
            worst_case: 1,
            best_case: 1,
            average: 1
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21"
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0,
                  10103.424681489398,
                  10386.249093903463,
                  10569.495877378191,
                  10706.97984382085,
                  10667.674671460776,
                  10406.302444301164,
                  10483.588042405607,
                  10644.196234056752,
                  10446.8129705065,
                  10516.44917131124,
                  10645.010367041865,
                  10797.739433388168,
                  11079.233838487078,
                  11349.104553224337,
                  11191.141085388223,
                  11320.666205805926,
                  11146.544717323159,
                  11064.714675522464,
                  11364.646333905981,
                  11455.86312127676,
                  11723.875159992233,
                  11693.259184530129,
                  11711.165085240269,
                  11494.219330918038,
                  11845.084941277732,
                  11894.65911282942,
                  12136.522125674459,
                  12160.674932683683,
                  12284.39062423503,
                  12354.687220512911,
                  12085.34291895627,
                  12088.871659170869,
                  12138.540683964971,
                  12387.095277965022,
                  12612.436724400772,
                  12931.993940731365,
                  13176.856655878557,
                  13254.169132643005,
                  13414.565747876448,
                  13341.786437603456,
                  13431.606897024814,
                  13816.372641774204,
                  14119.418536864709,
                  13933.091552270336,
                  13647.831904721403,
                  13839.905532011137,
                  14224.324179352834,
                  14442.935639689877,
                  14085.826124157953,
                  14020.642856628954,
                  13560.029808198771,
                  13335.38998707027,
                  13265.92326978261,
                  13691.957236723352,
                  13807.592175834132,
                  13069.31981172026,
                  13036.800665753079,
                  13192.424713087983,
                  12337.8159705111,
                  10908.848777811632,
                  10550.853482949471,
                  10973.13961440657,
                  10401.660487962248,
                  9783.9433623564,
                  10235.932353992554,
                  10832.994682868935,
                  11184.03607831223,
                  11227.846311449406,
                  11765.20195870677,
                  12072.596139344187,
                  12416.95087627774,
                  12241.850320900914,
                  12704.015931422911,
                  12811.834417760214,
                  12618.601746873126,
                  12878.69350225895,
                  13358.055337420506,
                  13584.493183685365,
                  13000.450490282134,
                  12658.81122916927,
                  13203.11064403807,
                  12918.99765145636,
                  13602.023996571183,
                  13928.581933339596,
                  13922.80370977347,
                  14459.04292850522,
                  14635.114586816066,
                  14984.631484099838,
                  15011.153757479267,
                  15371.127905787584,
                  15339.581057376092,
                  15141.923150465216,
                  15026.526719230813,
                  14557.442678130044,
                  13951.353969400938,
                  14863.346889296798,
                  14810.412097871715,
                  14977.828411989049,
                  15473.826048651892,
                  15868.154217605756,
                  16132.660627024692,
                  16123.604639287696,
                  15567.218101285005,
                  15945.244082586578,
                  16114.936348267576,
                  16380.848406190607,
                  16649.35271856135,
                  16459.338185020843,
                  16555.11766382539,
                  16672.15906257304,
                  17195.544702734365,
                  17372.736616789203,
                  17816.617285748875,
                  18062.597254864893,
                  18221.495326838696,
                  17951.687288119756,
                  18647.399878043125,
                  18217.669586358978,
                  18758.375792685354,
                  19347.075961881546,
                  19686.39623550114,
                  20020.524178015723,
                  19676.43426111371,
                  20352.8679724863,
                  20415.22815104859,
                  20475.495448600497,
                  20847.483605751422,
                  21222.467961374976,
                  20910.703723005925,
                  21587.148558384506,
                  21230.189392040054,
                  21705.264461414383,
                  22125.410552234825,
                  22129.06613929987,
                  21836.585754260246,
                  22647.78436774838,
                  22486.052140842043,
                  22562.1849250294,
                  22740.17417166149,
                  22398.688554569024,
                  22724.994239470354,
                  21718.832512796034,
                  21328.51194215735,
                  22498.188278691116,
                  22567.96849016009,
                  22215.850967858558,
                  21401.59997357005,
                  21459.08003127515,
                  22566.569018280694,
                  22687.832846883608,
                  22964.600957184663,
                  23140.133486257113,
                  23822.769775318913,
                  23842.612668133872,
                  23879.85640908558,
                  23451.902109420058,
                  24019.239172632915,
                  24380.193960091212,
                  24722.127473855275,
                  25430.082468870918,
                  25436.636467572454,
                  25696.50583892873,
                  25934.19971997728,
                  26113.366978385027,
                  26497.88148892462,
                  26592.277179265875,
                  27031.08760935813,
                  27473.86625417717,
                  28085.75755351447,
                  28364.300334980457,
                  29405.444473961583,
                  28489.344202574368,
                  28109.625960958736,
                  28140.541446621057,
                  28766.338175243094,
                  28927.609321875134,
                  29658.233922457985,
                  30480.066804793656,
                  30483.655871459865,
                  28687.733877865587,
                  29180.927058721405,
                  27267.794409689035,
                  29063.383600916713,
                  29838.708471756574,
                  30311.157541654182,
                  31204.139394909926,
                  29791.716904024797,
                  31462.932626725564,
                  31816.32065823638,
                  31514.21944697859,
                  31892.822043926742,
                  32420.813730424612,
                  33358.91398988723,
                  34078.1672527018,
                  34215.7519053806,
                  32219.923291743457,
                  28795.357360107824,
                  31685.20226332611,
                  33019.581749298966,
                  33647.26731695269,
                  35224.52016187965,
                  37080.0586661049,
                  36046.80508549476,
                  35456.60856043834,
                  38789.46491942636,
                  40229.770547347776,
                  40061.97535190529,
                  40943.098185226794,
                  42054.936996350094,
                  43834.23842125079,
                  44014.129794183915,
                  44975.14160121585,
                  45706.59483461573,
                  46765.3608550858,
                  44964.41061749094,
                  47217.45881735879
                ]
              }
            ]
          }
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "VTI",
              label: "Vanguard Total Stock Market",
            },
            {
              ticker: "IXUS",
              label: "iShares Core MSCI Total Intl Stk",
            },
            {
              ticker: "IEMG",
              label: "iShares Core MSCI Emerging Markets",
            },
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "IVE",
              label: "iShares S&P 500 Value",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [0.19, 0.26, 0.03, 0.07, 0.07, 0.24, 0.04, 0.04, 0.06],
            },
          ],
        },
        sectors_distribution: null,
      },
      {
        id: "542e2235-b955-4ea3-890a-e145dbac49f8",
        name: "Conservative portfolio",
        highlight:
          "This portfolio is suitable for cautious investors who are concerned about volatility but still are willing to take a moderate amount of risk for the benefit of moderate capital growth, usually with a short to mid term time horizon (3 – 7 years).",
        description:
          "This portfolio offers a moderate balance between volatility and potential capital growth, being a moderate blend between bonds (60%), and stocks (40%). This portfolio has a slightly higher market volatility but with a higher potential of capital growth.",
        category: "Investments",
        type: {
          id: "ee983394-3de4-40eb-8265-8527162a9322",
          name: "Standard",
          highlight:
            "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
          description:
            "Standard funds are funds that are not governed by specific requirements.",
          icon: "/standard.svg",
        },
        risk_level: {
          id: "dcd3b4a7-0eff-476f-a48f-7c95f0e7a954",
          name: "Conservative",
          rank: 2,
          description: "2. Conservative Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0
            },
            {
              period: "1m",
              value: 0.08,
              volume: 0
            },
            {
              period: "3m",
              value: 0.36,
              volume: 0
            },
            {
              period: "YTD",
              value: 5.07,
              volume: 0
            },
            {
              period: "1y",
              value: 11.68,
              volume: 0
            },
            {
              period: "3y",
              value: 9.8,
              volume: 0
            },
            {
              period: "5y",
              value: 8.57,
              volume: 0
            }
          ],
          forecast: {
            worst_case: 1,
            best_case: 1,
            average: 1
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21"
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0,
                  10080.097562028965,
                  10301.398476819457,
                  10438.4420221542,
                  10568.815395440817,
                  10566.073219655827,
                  10294.880339243853,
                  10333.713473042928,
                  10465.144984659748,
                  10364.718431243953,
                  10466.176317357003,
                  10569.746737107202,
                  10701.665283136623,
                  10863.433563005749,
                  11071.072453559564,
                  10982.87778268508,
                  11056.148171922752,
                  10905.877207010184,
                  10911.192386642098,
                  11140.400516760077,
                  11232.438179831932,
                  11373.890330210386,
                  11395.519021711923,
                  11373.736914195164,
                  11193.7671492903,
                  11440.905377875544,
                  11514.15197302031,
                  11673.253749262418,
                  11695.115614944954,
                  11746.467319790623,
                  11788.033659588364,
                  11601.417597083619,
                  11598.068504549483,
                  11680.332354772909,
                  11903.118125104216,
                  12090.217626079879,
                  12328.501272958609,
                  12531.112075854126,
                  12560.158301868618,
                  12664.471885888353,
                  12676.442946384312,
                  12728.942038446152,
                  13007.712994267982,
                  13174.9180113789,
                  13036.52567406484,
                  12886.569044530086,
                  13063.086829474938,
                  13343.641585539352,
                  13528.29849866914,
                  13360.558448234282,
                  13316.733831420346,
                  13101.477697030197,
                  12945.216502490133,
                  12904.16121921878,
                  13201.485417716347,
                  13227.034798500834,
                  12727.416042807392,
                  12721.552611435232,
                  12855.203933386292,
                  12214.014724628567,
                  11170.385293374391,
                  11049.600438566315,
                  11598.667113540716,
                  11132.592747603874,
                  10676.47539008609,
                  11023.616614036506,
                  11443.297232352166,
                  11707.283640113605,
                  11754.379846239664,
                  12166.793670969324,
                  12424.476286983594,
                  12706.043740982752,
                  12599.13507401667,
                  12964.32841078086,
                  12951.644572939527,
                  12885.226876649614,
                  13067.49456091499,
                  13386.769303054756,
                  13581.342235254728,
                  13240.963044229804,
                  13094.103296973964,
                  13496.755641820022,
                  13367.757630207609,
                  13823.460753625255,
                  14048.401127149511,
                  14004.787783348995,
                  14330.382539563972,
                  14443.76833749228,
                  14690.396516797153,
                  14697.590738549863,
                  15011.092494192235,
                  15049.122968255826,
                  14895.884746647334,
                  14899.930394024224,
                  14661.439582636162,
                  14295.578160441615,
                  14909.88820283172,
                  14858.057901617867,
                  15037.292745828503,
                  15404.619614626423,
                  15666.813044002014,
                  15813.998896061436,
                  15853.792790543597,
                  15537.908624580883,
                  15788.905687351282,
                  15972.301975596667,
                  16149.577125048514,
                  16342.518937537427,
                  16213.369444235635,
                  16291.407437765709,
                  16356.546561001414,
                  16672.910895960664,
                  16821.818475307206,
                  17122.90106303348,
                  17337.69318666646,
                  17337.645425099246,
                  17076.528519176714,
                  17554.0870778567,
                  17225.266390327677,
                  17642.669550722734,
                  18077.90810804456,
                  18291.138539687978,
                  18485.12384238628,
                  18334.906057327986,
                  18805.494615523327,
                  18839.31819272101,
                  18922.464047841255,
                  19232.61461099456,
                  19479.535412061392,
                  19258.317744589825,
                  19770.41783595476,
                  19499.3703962418,
                  19872.97016248447,
                  20188.56782995117,
                  20199.0314809752,
                  20115.81989509458,
                  20606.884386655533,
                  20519.70391817685,
                  20552.61369641355,
                  20647.16720920636,
                  20360.430658013633,
                  20624.76551446935,
                  19935.60639662581,
                  19719.667531425875,
                  20503.221869290646,
                  20528.326361962572,
                  20283.070068090536,
                  19808.127874437523,
                  19895.597525340545,
                  20682.814178100194,
                  20778.031842849345,
                  20963.309665168657,
                  21189.738375167613,
                  21676.204502926215,
                  21676.95059343054,
                  21704.765019486018,
                  21372.192267325907,
                  21603.21820644937,
                  21858.061059233736,
                  22097.945804175106,
                  22606.191150790284,
                  22607.437081020136,
                  22831.92409257908,
                  23029.557572998416,
                  23147.955503649857,
                  23423.467992158163,
                  23540.601384963666,
                  23799.91821264911,
                  24100.7560369364,
                  24500.108427108396,
                  24713.247980149405,
                  25342.050663764072,
                  24673.431160705495,
                  24458.410539401306,
                  24425.20524668912,
                  24879.896773549634,
                  24993.262364632163,
                  25478.677573979337,
                  26058.980787987148,
                  26025.808820886334,
                  24791.791537772675,
                  25164.30884879858,
                  24002.021579933004,
                  25252.12469007564,
                  25762.320960525947,
                  26201.87797668091,
                  26785.189748347024,
                  25957.89029915504,
                  27139.01181532004,
                  27385.81201779336,
                  27356.244527031762,
                  27569.796944233123,
                  27934.92017370528,
                  28558.13769614043,
                  29034.68525364734,
                  29254.55668115058,
                  28026.123357454846,
                  25708.59316916439,
                  27747.096118292455,
                  28681.232676127554,
                  29143.63721662521,
                  30284.62600870084,
                  31465.632535175384,
                  30770.231984695343,
                  30339.143785118657,
                  32641.917136878536,
                  33607.935960228,
                  33445.53041175267,
                  33930.66042905525,
                  34595.82577327216,
                  35830.04103755587,
                  35963.4354045581,
                  36658.92405284215,
                  37221.09568078742,
                  37913.4160556492,
                  36650.993654831036,
                  38125.08084328192
                ]
              }
            ]
          }
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "VTI",
              label: "Vanguard Total Stock Market",
            },
            {
              ticker: "IXUS",
              label: "iShares Core MSCI Total Intl Stk",
            },
            {
              ticker: "IEMG",
              label: "iShares Core MSCI Emerging Markets",
            },
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "IVE",
              label: "iShares S&P 500 Value",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [0.175, 0.175, 0.02, 0.03, 0.03, 0.29, 0.08, 0.12, 0.08],
            },
          ],
        },
        sectors_distribution: null,
      },
    ],
  },
  portfolioSummarySingle: {
    pageable: null,
    totalElements: 1,
    totalPages: null,
    last: null,
    first: null,
    sort: null,
    size: 100,
    number: null,
    numberOfElements: 1,
    empty: null,
    content: [
      {
        id: "75a9ef17-5ddd-443c-a92d-ae0d997c275b",
        name: "Aggressive portfolio",
        highlight:
          "This portfolio is suitable only for seasoned investors who have a clear understanding of investment risks and have a much longer time horizon (10+ years).",
        description:
          "This portfolio has a very high concentration of stocks (80%) than bonds (20%), offering the highest potential of capturing overall market growth. However, it also has the highest exposure to risks of market volatility.",
        category: "Investments",
        type: {
          id: "97d0219d-d1e9-4a5e-9013-bdb4cb8bdb07",
          name: "Sustainable Investing",
          highlight:
            "Invest in portfolios that have a selective choice of companies that implement an Environmental, Social and corporate Governance policies.",
          description:
            "ESG stands for Environmental, Social, and Governance. Investors are increasingly applying these non-financial factors as part of their analysis process to identify material risks and growth opportunities. ESG metrics are not commonly part of mandatory financial reporting, though companies are increasingly making disclosures in their annual report or in a standalone sustainability report. Numerous institutions, such as the Sustainability Accounting Standards Board (SASB), the Global Reporting Initiative (GRI), and the Task Force on Climate-related Financial Disclosures (TCFD) are working to form standards and define materiality to facilitate incorporation of these factors into the investment process.",
          icon: "/esg.svg",
        },
        risk_level: {
          id: "3f150447-33dd-4b7b-a485-d27e1b60e4b4",
          name: "Aggressive",
          rank: 5,
          description: "5. Aggressive Risk Level",
        },
        performance: {
          overview: [
            {
              period: "MTD",
              value: -0.02,
              volume: 0,
            },
            {
              period: "1m",
              value: 1.35,
              volume: 0,
            },
            {
              period: "3m",
              value: 5.62,
              volume: 0,
            },
            {
              period: "YTD",
              value: 11.81,
              volume: 0,
            },
            {
              period: "1y",
              value: 25.13,
              volume: 0,
            },
            {
              period: "3y",
              value: 14.02,
              volume: 0,
            },
            {
              period: "5y",
              value: 14.13,
              volume: 0,
            },
          ],
          forecast: {
            worst_case: 1.0,
            best_case: 1.0,
            average: 1.0,
          },
          charts: {
            labels: [
              "10/03",
              "11/03",
              "12/03",
              "01/04",
              "02/04",
              "03/04",
              "04/04",
              "05/04",
              "06/04",
              "07/04",
              "08/04",
              "09/04",
              "10/04",
              "11/04",
              "12/04",
              "01/05",
              "02/05",
              "03/05",
              "04/05",
              "05/05",
              "06/05",
              "07/05",
              "08/05",
              "09/05",
              "10/05",
              "11/05",
              "12/05",
              "01/06",
              "02/06",
              "03/06",
              "04/06",
              "05/06",
              "06/06",
              "07/06",
              "08/06",
              "09/06",
              "10/06",
              "11/06",
              "12/06",
              "01/07",
              "02/07",
              "03/07",
              "04/07",
              "05/07",
              "06/07",
              "07/07",
              "08/07",
              "09/07",
              "10/07",
              "11/07",
              "12/07",
              "01/08",
              "02/08",
              "03/08",
              "04/08",
              "05/08",
              "06/08",
              "07/08",
              "08/08",
              "09/08",
              "10/08",
              "11/08",
              "12/08",
              "01/09",
              "02/09",
              "03/09",
              "04/09",
              "05/09",
              "06/09",
              "07/09",
              "08/09",
              "09/09",
              "10/09",
              "11/09",
              "12/09",
              "01/10",
              "02/10",
              "03/10",
              "04/10",
              "05/10",
              "06/10",
              "07/10",
              "08/10",
              "09/10",
              "10/10",
              "11/10",
              "12/10",
              "01/11",
              "02/11",
              "03/11",
              "04/11",
              "05/11",
              "06/11",
              "07/11",
              "08/11",
              "09/11",
              "10/11",
              "11/11",
              "12/11",
              "01/12",
              "02/12",
              "03/12",
              "04/12",
              "05/12",
              "06/12",
              "07/12",
              "08/12",
              "09/12",
              "10/12",
              "11/12",
              "12/12",
              "01/13",
              "02/13",
              "03/13",
              "04/13",
              "05/13",
              "06/13",
              "07/13",
              "08/13",
              "09/13",
              "10/13",
              "11/13",
              "12/13",
              "01/14",
              "02/14",
              "03/14",
              "04/14",
              "05/14",
              "06/14",
              "07/14",
              "08/14",
              "09/14",
              "10/14",
              "11/14",
              "12/14",
              "01/15",
              "02/15",
              "03/15",
              "04/15",
              "05/15",
              "06/15",
              "07/15",
              "08/15",
              "09/15",
              "10/15",
              "11/15",
              "12/15",
              "01/16",
              "02/16",
              "03/16",
              "04/16",
              "05/16",
              "06/16",
              "07/16",
              "08/16",
              "09/16",
              "10/16",
              "11/16",
              "12/16",
              "01/17",
              "02/17",
              "03/17",
              "04/17",
              "05/17",
              "06/17",
              "07/17",
              "08/17",
              "09/17",
              "10/17",
              "11/17",
              "12/17",
              "01/18",
              "02/18",
              "03/18",
              "04/18",
              "05/18",
              "06/18",
              "07/18",
              "08/18",
              "09/18",
              "10/18",
              "11/18",
              "12/18",
              "01/19",
              "02/19",
              "03/19",
              "04/19",
              "05/19",
              "06/19",
              "07/19",
              "08/19",
              "09/19",
              "10/19",
              "11/19",
              "12/19",
              "01/20",
              "02/20",
              "03/20",
              "04/20",
              "05/20",
              "06/20",
              "07/20",
              "08/20",
              "09/20",
              "10/20",
              "11/20",
              "12/20",
              "01/21",
              "02/21",
              "03/21",
              "04/21",
              "05/21",
              "06/21",
              "07/21",
              "08/21",
              "09/21",
              "10/21",
            ],
            series: [
              {
                name: "history",
                data: [
                  10000.0, 10126.753246642005, 10471.099302222796,
                  10700.546021670345, 10845.143573246918, 10769.276978294378,
                  10517.724304800553, 10633.46003143945, 10823.248866282522,
                  10528.904367574221, 10566.721363451139, 10720.270001899009,
                  10893.809110519807, 11295.028397947817, 11627.131757954578,
                  11399.398246962919, 11585.18041934524, 11387.212190270573,
                  11218.2311665669, 11588.893404586877, 11679.283322798403,
                  12073.857974905039, 11990.991923033615, 12048.589192886444,
                  11794.671212243406, 12249.260274745044, 12275.162233854266,
                  12599.79073329954, 12626.230470525465, 12822.309709806465,
                  12921.341840226625, 12569.27004845645, 12579.672254158617,
                  12596.745819444423, 12871.067615262811, 13134.652774833565,
                  13535.485026026618, 13822.594698887213, 13948.173001715168,
                  14164.657320831737, 14007.125043068634, 14134.272361276562,
                  14625.026644804273, 15063.912700657847, 14829.65237315357,
                  14409.097270838907, 14616.723981045547, 15105.00324835197,
                  15357.563854442742, 14811.086990657539, 14724.54526337676,
                  14018.575411798061, 13725.55951895606, 13627.684899443851,
                  14182.428030403695, 14388.150447457152, 13411.225748200794,
                  13352.046086840748, 13529.646158637603, 12461.613442108937,
                  10647.307232574876, 10052.102148019952, 10347.609346810546,
                  9670.723649907375, 8891.409636313394, 9448.247120906783,
                  10222.696073553745, 10660.786797055653, 10701.309087932916,
                  11363.608059568614, 11720.709540130913, 12127.85792816888,
                  11884.559437411895, 12443.701604056925, 12672.024598316213,
                  12351.973990609182, 12689.889917584876, 13329.340857624216,
                  13587.64017433525, 12759.931344298204, 12223.516501846156,
                  12909.467870412196, 12470.231928274417, 13380.58553464439,
                  13808.7630755372, 13840.811911193035, 14587.70216133705,
                  14826.457576527959, 15278.858737156119, 15324.713411132065,
                  15731.155941954774, 15630.04087949096, 15387.951003778164,
                  15153.116989509235, 14453.443513623948, 13607.130594379998,
                  14816.805148077498, 14762.763653189319, 14918.361085915536,
                  15543.029543340828, 16069.494252969973, 16451.31846092271,
                  16393.410906989022, 15596.522035880207, 16101.57419569911,
                  16257.56752629156, 16612.120602943683, 16956.17771703805,
                  16705.301582307122, 16818.822881710097, 16987.773731842055,
                  17718.175540227166, 17923.65406303691, 18510.32502376362,
                  18787.50622524419, 19105.341073775387, 18826.839741223965,
                  19740.71187355466, 19210.071372330312, 19874.079507796734,
                  20616.251364780695, 21081.651146755456, 21555.920377058144,
                  21017.956570865885, 21900.233993519647, 21991.132541545,
                  22028.519044590797, 22462.347991305392, 22965.399181037894,
                  22563.091404534087, 23403.87518987617, 22960.998593598586,
                  23537.555517632165, 24062.24947906186, 24059.100921032474,
                  23557.343636914273, 24688.67729643896, 24452.398438268952,
                  24571.749125782466, 24833.17521257685, 24436.948188803326,
                  24825.214553116133, 23502.052991342785, 22937.350032029182,
                  24493.149636743572, 24607.60385146133, 24148.62778489534,
                  22995.06929513476, 23022.55271218126, 24450.321126178216,
                  24597.621462080835, 24965.88904633326, 25090.52296161165,
                  25969.326637450187, 26008.267124751226, 26054.93509183638,
                  25531.604171022376, 26435.258949392875, 26902.320759486003,
                  27346.304107137315, 28253.96935534202, 28265.825275557014,
                  28561.07933901238, 28838.83890422869, 29078.77134829,
                  29572.290627805043, 29643.948596566508, 30262.254193537352,
                  30846.97424036963, 31671.39679785393, 32015.349144138727,
                  33468.829983886724, 32305.246341842656, 31760.829165952684,
                  31855.87414475189, 32652.77902304567, 32861.94605620293,
                  33837.77957514385, 34901.14475120007, 34941.490660403295,
                  32583.666323461985, 33197.53417505117, 30533.561513497745,
                  32874.63565966304, 33915.092030485655, 34420.42341377266,
                  35623.07768127265, 33625.537014848946, 35786.84541583756,
                  36246.82144473445, 35672.183769275915, 36215.83875353215,
                  36906.699282908405, 38159.68460068603, 39121.64595173602,
                  39176.9357832228, 36413.71789597694, 31882.11121039545,
                  35623.30065364453, 37357.9250172133, 38150.88885474466,
                  40164.40452041036, 42694.47812336029, 41323.36841746114,
                  40574.06711400846, 44936.99957902902, 46851.59445872466,
                  46678.41018965149, 47955.52584269934, 49514.03673046871,
                  51838.424087121064, 52064.81268359873, 53291.347119331745,
                  54192.08202864807, 55617.29312761987, 53277.81581931488,
                  55986.45922736799,
                ],
              },
            ],
          },
        },
        geo_distribution: {
          labels: [
            "United States",
            "United Kingdom",
            "Canada",
            "Japan",
            "Luxembourg",
            "Philippines",
            "Germany",
            "Mexico",
            "Netherlands",
            "Colombia",
          ],
          series: [
            {
              name: "distribution",
              data: [92.5, 1.28, 1.22, 0.68, 0.46, 0.46, 0.42, 0.38, 0.35, 0.2],
            },
          ],
        },
        assets_allocation: {
          labels: [
            {
              ticker: "VT",
              label: "Vanguard Total World Stock",
            },
            {
              ticker: "VTI",
              label: "Vanguard Total Stock Market",
            },
            {
              ticker: "IXUS",
              label: "iShares Core MSCI Total Intl Stk",
            },
            {
              ticker: "IEMG",
              label: "iShares Core MSCI Emerging Markets",
            },
            {
              ticker: "EES",
              label: "WisdomTree US SmallCap Earnings",
            },
            {
              ticker: "DGRW",
              label: "WisdomTree US Quality Dividend Gr",
            },
            {
              ticker: "IVE",
              label: "iShares S&P 500 Value",
            },
            {
              ticker: "BND",
              label: "Vanguard Total Bond Market",
            },
            {
              ticker: "VTEB",
              label: "Vanguard Tax-Exempt Bond",
            },
            {
              ticker: "VMBS",
              label: "Vanguard Mortgage-Backed Secs",
            },
            {
              ticker: "JPST",
              label: "JPMorgan Ultra-Short Income",
            },
          ],
          series: [
            {
              name: "allocation",
              data: [
                0.26, 0.15, 0.19, 0.06, 0.03, 0.04, 0.08, 0.11, 0.03, 0.03,
                0.02,
              ],
            },
          ],
        },
        sectors_distribution: null,
      },
    ],
  },
  portfolioTypesList: {
    pageable: {
      sort: {
        sorted: true,
        unsorted: false,
        empty: false,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    last: false,
    totalPages: 1,
    totalElements: 3,
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    first: true,
    numberOfElements: 3,
    size: 3,
    number: 0,
    empty: false,
    content: [
      {
        id: "ee983394-3de4-40eb-8265-8527162a9322",
        name: "Standard",
        highlight:
          "Invest in general stocks and bonds without specific selection criteria. This is the common tried and tested selection for years.",
        description:
          "Standard funds are funds that are not governed by specific requirements.",
        icon: "/standard.svg",
      },
      {
        id: "97d0219d-d1e9-4a5e-9013-bdb4cb8bdb07",
        name: "Sustainable Investing",
        highlight:
          "Invest in portfolios that have a selective choice of companies that implement an Environmental, Social and corporate Governance policies.",
        description:
          "ESG stands for Environmental, Social, and Governance. Investors are increasingly applying these non-financial factors as part of their analysis process to identify material risks and growth opportunities. ESG metrics are not commonly part of mandatory financial reporting, though companies are increasingly making disclosures in their annual report or in a standalone sustainability report. Numerous institutions, such as the Sustainability Accounting Standards Board (SASB), the Global Reporting Initiative (GRI), and the Task Force on Climate-related Financial Disclosures (TCFD) are working to form standards and define materiality to facilitate incorporation of these factors into the investment process.",
        icon: "/esg.svg",
      },
      {
        id: "b86955c8-0a6d-489a-b0ee-8b7e78080e63",
        name: "Sharia Compliant",
        highlight:
          "Invest in line with the Islamic Shari’a, this excludes companies that rely on interest debt, alcohol, sexual content or gambling as part of their business.",
        description:
          "Shariah-compliant funds are investment funds governed by the requirements of Shariah law and the principles of the Islamic religion. Shariah-compliant funds are considered to be a type of socially responsible investing.",
        icon: "/sharia.svg",
      },
    ],
  },
  newInvestmentAccountResponse: {
    account_id: "1282fc79-257e-493f-afe8-61593f5993a1",
    label: "My First Investment",
    investment_definition_id: "95398d1f-abef-4a52-a39f-74e8609411c7",
    category: "investments",
    icon: "string",
    goal: {
      target_date: "2021-12-30T08:30:00Z",
      target_amount: {
        amount: 1000000,
        currency_code: "AED",
      },
    },
    status: "REGISTERED",
    status_detail: "Your order has been registered",
    creation_date: "2021-01-30T08:30:00Z",
    last_updated_date: "2021-02-18T17:44:00Z",
  },
  contributeWithdrawResponse: {
    amount: 1000000,
    currency_code: "AED",
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    label: "string",
    source_account: "1282fc79-257e-493f-afe8-61593f5993a1",
    destination_account: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
    date: "2021-01-30T08:30:00Z",
    orderType: "ONE_TIME",
  },
  contributeWithdrawResponse: {
    amount: 1000000,
    currency_code: "AED",
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    label: "string",
    source_account: "1282fc79-257e-493f-afe8-61593f5993a1",
    destination_account: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
    date: "2021-01-30T08:30:00Z",
    orderType: "ONE_TIME",
  },
  newDocument: {
    document_id: "kRoUQijHk3AeCW2DvBaJmG",
    session_id: "vRxnAypGTeSH5dkx4MewWB",
  },
  envInvestmentOrderList: {
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: 2,
    totalPages: 1,
    last: true,
    first: true,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    size: 10,
    number: 0,
    numberOfElements: 2,
    empty: false,
    content: [
      {
        amount: 4500000,
        currency_code: "AED",
        by_month_day: null,
        id: "340c3d13-e16a-42cf-8f04-f6276a34f16e",
        label: "Initial Contribution for Test 123",
        source_account: "2082d150-9ade-4db2-8a5f-77fd12bc9a4e",
        destination_account: "ce90dbf0-e63c-4320-8626-56795619a1b1",
        date: "2021-10-14T12:05:41.159105Z",
        orderType: "ONE_TIME",
        orderStatus: "PROCESSED",
        creation_date: "2021-10-14T12:05:41.159144Z",
      },
      {
        amount: 150000,
        currency_code: "AED",
        by_month_day: null,
        id: "52515972-1fbd-4fa0-96a3-23671ae20cae",
        label: "Initial Contribution for Test",
        source_account: "2082d150-9ade-4db2-8a5f-77fd12bc9a4e",
        destination_account: "7c888dfc-4318-4a0e-ba45-9f7d9f3a5d48",
        date: "2021-10-14T12:06:08.479093Z",
        orderType: "ONE_TIME",
        orderStatus: "PROCESSED",
        creation_date: "2021-10-14T12:06:08.479146Z",
      },
    ],
  },
  QATestInvestmentOrdersList: {
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: 2,
    totalPages: 1,
    last: true,
    first: true,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    size: 10,
    number: 0,
    numberOfElements: 2,
    empty: false,
    content: [
      {
        amount: 4500000,
        currency_code: "AED",
        by_month_day: null,
        id: "340c3d13-e16a-42cf-8f04-f6276a34f16e",
        label: "Initial Contribution for Test 123",
        source_account: "2082d150-9ade-4db2-8a5f-77fd12bc9a4e",
        destination_account: "ce90dbf0-e63c-4320-8626-56795619a1b1",
        date: "2021-10-14T12:05:41.159105Z",
        orderType: "ONE_TIME",
        orderStatus: "PROCESSED",
        creation_date: "2021-10-14T12:05:41.159144Z",
      },
      {
        amount: 150000,
        currency_code: "AED",
        by_month_day: null,
        id: "52515972-1fbd-4fa0-96a3-23671ae20cae",
        label: "Initial Contribution for Test",
        source_account: "2082d150-9ade-4db2-8a5f-77fd12bc9a4e",
        destination_account: "7c888dfc-4318-4a0e-ba45-9f7d9f3a5d48",
        date: "2021-10-14T12:06:08.479093Z",
        orderType: "ONE_TIME",
        orderStatus: "PROCESSED",
        creation_date: "2021-10-14T12:06:08.479146Z",
      },
    ],
  },
  investmentOrdersList: {
    pageable: {
      sort: {
        sorted: true,
        unsorted: false,
        empty: false,
      },
      pageNumber: 0,
      pageSize: 10,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: 1,
    totalPages: 1,
    last: true,
    first: true,
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    size: 10,
    number: 0,
    numberOfElements: 1,
    empty: false,
    content: [
      {
        amount: 1000000,
        currency_code: "AED",
        source: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
        destination: "2ae08f77-512b-45ef-87d3-40aca2db0bed",
        date: "2021-09-15T08:04:42.117352Z",
        label: "Initial Contribution for Savings",
        intent_id: "da2aaaaf-a239-4b36-bcfe-f278f8363155",
        status: "REGISTERED",
        multileg: null,
        legs: null,
      },
    ],
  },
};
/* eslint-enable */
