/* eslint-disable */
export const dashboardData = {
  "gratuity": {
    "eligibility": {
      "breakdown": {
        "labels": [
          "<1Y",
          "<5Y",
          "5Y+"
        ],
        "series": [
          {
            "name": "breakdown",
            "data": [
              12,
              40,
              48
            ]
          }
        ]
      },
      "deltaForecast": {
        "labels": [
          "12/21",
          "01/22",
          "02/22",
          "03/22",
          "04/22",
          "05/22",
          "06/22",
          "07/22",
          "08/22",
          "09/22",
          "10/22",
          "11/22"
        ],
        "series": [
          {
            "name": "<1Y",
            "data": [
              -1,
              1,
              0,
              -1,
              -1,
              -1,
              -2,
              2,
              0,
              1,
              1,
              1
            ]
          },
          {
            "name": "<5Y",
            "data": [
              0,
              1,
              2,
              0,
              0,
              0,
              1,
              2,
              1,
              2,
              1,
              1
            ]
          },
          {
            "name": "5Y+",
            "data": [
              1,
              0,
              2,
              1,
              1,
              1,
              3,
              0,
              1,
              1,
              0,
              0
            ]
          }
        ]
      },
      "forecast": {
        "labels": [
          "12/21",
          "01/22",
          "02/22",
          "03/22",
          "04/22",
          "05/22",
          "06/22",
          "07/22",
          "08/22",
          "09/22",
          "10/22",
          "11/22"
        ],
        "series": [
          {
            "name": "<1Y",
            "data": [
              12,
              11,
              9,
              9,
              9,
              9,
              8,
              6,
              5,
              3,
              2,
              1
            ]
          },
          {
            "name": "<5Y",
            "data": [
              40,
              41,
              41,
              40,
              39,
              38,
              36,
              38,
              38,
              39,
              40,
              41
            ]
          },
          {
            "name": "5Y+",
            "data": [
              48,
              48,
              50,
              51,
              52,
              53,
              56,
              56,
              57,
              58,
              58,
              58
            ]
          }
        ]
      }
    },
    "gratuity": {
      "forecast": {
        "labels": [
          "12/21",
          "01/22",
          "02/22",
          "03/22",
          "04/22",
          "05/22",
          "06/22",
          "07/22",
          "08/22",
          "09/22",
          "10/22",
          "11/22"
        ],
        "series": [
          {
            "name": "Total",
            "data": [
              4950440,
              5042007,
              5130789,
              5216121,
              5299096,
              5385087,
              5485671,
              5598276,
              5700002,
              5806506,
              5903790,
              6007075
            ]
          }
        ]
      }
    }
  },
  "summary": {
    "as_of_date": null,
    "last_updated": null,
    "currency": "AED",
    "company": {
      "id": "1d9a9fda-c942-415c-84a5-23c239ff5fd0",
      "name": null,
      "compliantMonthlyContribution": 81142,
      "deltaEligibibleThisMonth": 0,
      "deltaEligibibleThisMonthLess5Y": -1,
      "deltaEligibibleThisMonthMore5Y": 1,
      "nbEligibleLess5Y": 40,
      "nbEligibleMore5Y": 48,
      "nbEmployees": 100,
      "nbNotEligible": 12,
      "totalGratuity": 4945101
    },
    "departments": [
      {
        "id": "709438c8d0ff178fa38c1fa7f8e98c8eadd21238",
        "name": "Sales",
        "compliantMonthlyContribution": 7311,
        "deltaEligibibleThisMonth": 0,
        "deltaEligibibleThisMonthLess5Y": 0,
        "deltaEligibibleThisMonthMore5Y": 0,
        "nbEligibleLess5Y": 4,
        "nbEligibleMore5Y": 4,
        "nbEmployees": 9,
        "nbNotEligible": 1,
        "totalGratuity": 440977
      },
      {
        "id": "709438c8d0ff178fa38c1fa7f8e98c8eadd21238",
        "name": "Finance",
        "compliantMonthlyContribution": 7380,
        "deltaEligibibleThisMonth": 0,
        "deltaEligibibleThisMonthLess5Y": 0,
        "deltaEligibibleThisMonthMore5Y": 0,
        "nbEligibleLess5Y": 6,
        "nbEligibleMore5Y": 3,
        "nbEmployees": 11,
        "nbNotEligible": 2,
        "totalGratuity": 389560
      },
      {
        "id": "709438c8d0ff178fa38c1fa7f8e98c8eadd21238",
        "name": "Marketing",
        "compliantMonthlyContribution": 9282,
        "deltaEligibibleThisMonth": 0,
        "deltaEligibibleThisMonthLess5Y": 0,
        "deltaEligibibleThisMonthMore5Y": 0,
        "nbEligibleLess5Y": 8,
        "nbEligibleMore5Y": 4,
        "nbEmployees": 13,
        "nbNotEligible": 1,
        "totalGratuity": 548850
      },
      {
        "id": "709438c8d0ff178fa38c1fa7f8e98c8eadd21238",
        "name": "Engineering",
        "compliantMonthlyContribution": 57168,
        "deltaEligibibleThisMonth": 0,
        "deltaEligibibleThisMonthLess5Y": -1,
        "deltaEligibibleThisMonthMore5Y": 1,
        "nbEligibleLess5Y": 22,
        "nbEligibleMore5Y": 37,
        "nbEmployees": 67,
        "nbNotEligible": 8,
        "totalGratuity": 3565714
      }
    ]
  },
  "employee_set_version": 0
}
