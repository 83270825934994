import { instance } from ".";
import { store } from "../redux/storeConfig/store";
import countries from "./countries";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");
export const countriesList = countries;

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => sessionStorage.getItem("userData");
export const getUserData = () => JSON.parse(sessionStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const isRole = (role, hasOneRole) => {
  /*
    expects a role to be passed in the props
    "eosb-senior-manager || academy-user || eosb-company-admin || eosb-finance || eosb-hr || eosb-user"

    returns true if the user has the role
  */
  const userData = getUserData();

  if (!userData) return false;

  return hasOneRole ? userData.roles === role : userData.roles.includes(role);
};

export const getReadableRoles = () => {
  const roles_map = {
    "eosb-company-admin": "Admin",
    "eosb-senior-manager": "Senior Manager",
    "eosb-finance": "Finance",
    "eosb-hr": "Human Resources",
    "academy-user": "Academy User",
    "eosb-user": "User",
  };
  const userData = getUserData();
  if (!userData) return null;
  return userData.roles.split(",").map((r) => roles_map[r]);
};

export const updateClipboard = (newClip, callback) => {
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state == "granted" || result.state == "prompt") {
      navigator.clipboard.writeText(newClip).then(
        function () {
          /* clipboard successfully set */
          callback(true);
        },
        function () {
          /* clipboard write failed */
          callback(false);
        }
      );
    }
  });
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const arrayMax = (arr) => {
  return arr.reduce(function (p, v) {
    return p > v ? p : v;
  });
};
export const arrayMin = (arr) => {
  return arr.reduce(function (p, v) {
    return p < v ? p : v;
  });
};

export const getCompanyName = (company_id) => {
  let { name_mappings } = store.getState().cache;
  let company = name_mappings.filter(
    (company) => company.company_id === company_id
  );
  if (company.length > 0) return company[0].company_name;
  else return "";
};

export const getTableRoles = (roles) => {
  // eosb-user
  // eosb-hr
  // eosb-finance
  // eosb-senior-manager
  // eosb-company-admin

  // eosb-admin
  // finflx-finance
  // finflx-compliance
  // finflx-support-officer

  let roles_arr = roles.split(",");
  let roles_map = {
    "eosb-company-admin": "Company Admin",
    "eosb-senior-manager": "Senior Manager",
    "eosb-finance": "Finance",
    "eosb-hr": "Human Resources",
    "academy-user": "Academy User",
    "eosb-user": "User",
    "finflx-onboarding-agent": "finflx-onboarding-agent",
    "eosb-admin": "Admin",
    "finflx-finance": "Finflx Finance",
    "finflx-compliance": "Finflx compliance",
    "finflx-support-officer": "Finflx support",
  };
  let roles_arr_new = roles_arr.map((r) => roles_map[r]);
  return roles_arr_new.join(", ");
};

export const downloadFile = ({ file_id, onFinish }) => {
  instance
    .get(`/api/documents/${file_id}`, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${+new Date()}.${res.data.type.split("/")[1]}`;
      link.click();
      onFinish(true);
    })
    .catch(() => {
      onFinish(false);
    });
};
export const viewFile = ({ file_id, title = "File", onFinish = () => {} }) => {
  instance
    .get(`/api/documents/${file_id}`, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      });
      let url = window.URL.createObjectURL(blob);
      let newWindow = window.open(url);
      setTimeout(function () {
        if (newWindow?.document) {
          newWindow.document.title = title;
        }
      }, 100);

      onFinish(true);
    })
    .catch(() => {
      onFinish(false);
    });
};
export const getFileUrl = async ({ file_id }) => {
  const res = await instance.get(`/api/documents/${file_id}`, {
    responseType: "blob",
  });
  // .then((res) => {
  const blob = new Blob([res.data], {
    type: res.data.type,
  });
  let url = window.URL.createObjectURL(blob);

  return [url, blob.type];
  // })
  // .catch((err) => {});
};
export const downloadSavingsReport = ({ onFinish = () => {} }) => {
  instance
    .get(`/api/savings-report`, {
      responseType: "blob",
    })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `savings-report.xlsx`;
      link.click();
      onFinish(true);
    })
    .catch((err) => {
      console.log(err);
      onFinish(false);
    });
};
