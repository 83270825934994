/* eslint-disable */
export const simulationsData = {
  "COVERAGE-1M-0.02": {
    "id": "7389234839175762193",
    "name": "2% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.175156",
    "lastUpdated": "2021-12-29T18:27:08.175164",
    "author": "John Smith",
    "criteria": {
      "name": "2% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.02,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 377645,
      "ratio": 0.0762851382907378,
      "total": 4950440,
      "initialMonthlyContribAvg": 377645,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 4204,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            377645,
            4204,
            4204,
            4204,
            4204,
            4204,
            4204,
            4204,
            4204,
            4204,
            4204,
            4210
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            377645,
            381849,
            386053,
            390257,
            394461,
            398665,
            402869,
            407073,
            411277,
            415481,
            419685,
            423895
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-1M-0.05": {
    "id": "8341436429725081290",
    "name": "5% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.279378",
    "lastUpdated": "2021-12-29T18:27:08.279386",
    "author": "John Smith",
    "criteria": {
      "name": "5% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.05,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 831501,
      "ratio": 0.16796506977157585,
      "total": 4950440,
      "initialMonthlyContribAvg": 831501,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 10197,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            831501,
            10197,
            10197,
            10197,
            10197,
            10197,
            10197,
            10197,
            10197,
            10197,
            10197,
            10207
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            831501,
            841698,
            851895,
            862092,
            872289,
            882486,
            892683,
            902880,
            913077,
            923274,
            933471,
            943678
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-1M-0.1": {
    "id": "442089488806583576",
    "name": "10% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.385943",
    "lastUpdated": "2021-12-29T18:27:08.385965",
    "author": "John Smith",
    "criteria": {
      "name": "10% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.1,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1517258,
      "ratio": 0.3064895241635087,
      "total": 4950440,
      "initialMonthlyContribAvg": 1517258,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 19212,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            1517258,
            19212,
            19212,
            19212,
            19212,
            19212,
            19212,
            19212,
            19212,
            19212,
            19212,
            19216
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            1517258,
            1536470,
            1555682,
            1574894,
            1594106,
            1613318,
            1632530,
            1651742,
            1670954,
            1690166,
            1709378,
            1728594
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-1M-0.2": {
    "id": "3014983222923371593",
    "name": "20% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.528838",
    "lastUpdated": "2021-12-29T18:27:08.528849",
    "author": "John Smith",
    "criteria": {
      "name": "20% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.2,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 2522170,
      "ratio": 0.5094840054621408,
      "total": 4950440,
      "initialMonthlyContribAvg": 2522170,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 34219,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            2522170,
            34219,
            34219,
            34219,
            34219,
            34219,
            34219,
            34219,
            34219,
            34219,
            34219,
            34223
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            2522170,
            2556389,
            2590608,
            2624827,
            2659046,
            2693265,
            2727484,
            2761703,
            2795922,
            2830141,
            2864360,
            2898583
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-1M-0.3": {
    "id": "6438403567679320341",
    "name": "30% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.684315",
    "lastUpdated": "2021-12-29T18:27:08.684326",
    "author": "John Smith",
    "criteria": {
      "name": "30% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.3,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 3212002,
      "ratio": 0.6488316190076033,
      "total": 4950440,
      "initialMonthlyContribAvg": 3212002,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 48498,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            3212002,
            48498,
            48498,
            48498,
            48498,
            48498,
            48498,
            48498,
            48498,
            48498,
            48498,
            48504
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            3212002,
            3260500,
            3308998,
            3357496,
            3405994,
            3454492,
            3502990,
            3551488,
            3599986,
            3648484,
            3696982,
            3745486
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-1M-0.5": {
    "id": "3298183081151344509",
    "name": "50% Top contributors in 1M",
    "creationDate": "2021-12-29T18:27:08.793367",
    "lastUpdated": "2021-12-29T18:27:08.793390",
    "author": "John Smith",
    "criteria": {
      "name": "50% Top contributors in 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": 0.5,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 4206606,
      "ratio": 0.8497438611517361,
      "total": 4950440,
      "initialMonthlyContribAvg": 4206606,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 67507,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            4206606,
            67507,
            67507,
            67507,
            67507,
            67507,
            67507,
            67507,
            67507,
            67507,
            67507,
            67517
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            4206606,
            4274113,
            4341620,
            4409127,
            4476634,
            4544141,
            4611648,
            4679155,
            4746662,
            4814169,
            4881676,
            4949193
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.02": {
    "id": "4062037331962138087",
    "name": "2% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:08.894899",
    "lastUpdated": "2021-12-29T18:27:08.894907",
    "author": "John Smith",
    "criteria": {
      "name": "2% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.02,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 385122,
      "ratio": 0.07506097015488261,
      "total": 5130789,
      "initialMonthlyContribAvg": 128374,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 4308,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            128374,
            128374,
            128374,
            4308,
            4308,
            4308,
            4308,
            4308,
            4308,
            4308,
            4308,
            4309
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            128374,
            256748,
            385122,
            389430,
            393738,
            398046,
            402354,
            406662,
            410970,
            415278,
            419586,
            423895
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.05": {
    "id": "8481117129106500845",
    "name": "5% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:08.996083",
    "lastUpdated": "2021-12-29T18:27:08.996092",
    "author": "John Smith",
    "criteria": {
      "name": "5% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.05,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 849326,
      "ratio": 0.1655351642798018,
      "total": 5130789,
      "initialMonthlyContribAvg": 283108,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 10483,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            283108,
            283108,
            283110,
            10483,
            10483,
            10483,
            10483,
            10483,
            10483,
            10483,
            10483,
            10488
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            283108,
            566216,
            849326,
            859809,
            870292,
            880775,
            891258,
            901741,
            912224,
            922707,
            933190,
            943678
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.1": {
    "id": "6905087984945292371",
    "name": "10% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:09.095674",
    "lastUpdated": "2021-12-29T18:27:09.095683",
    "author": "John Smith",
    "criteria": {
      "name": "10% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.1,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1551419,
      "ratio": 0.302374352170787,
      "total": 5130789,
      "initialMonthlyContribAvg": 517139,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 19686,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            517139,
            517139,
            517141,
            19686,
            19686,
            19686,
            19686,
            19686,
            19686,
            19686,
            19686,
            19687
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            517139,
            1034278,
            1551419,
            1571105,
            1590791,
            1610477,
            1630163,
            1649849,
            1669535,
            1689221,
            1708907,
            1728594
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.2": {
    "id": "7780990538032674342",
    "name": "20% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:09.204484",
    "lastUpdated": "2021-12-29T18:27:09.204516",
    "author": "John Smith",
    "criteria": {
      "name": "20% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.2,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 2580927,
      "ratio": 0.5030273121736247,
      "total": 5130789,
      "initialMonthlyContribAvg": 860309,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 35295,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            860309,
            860309,
            860309,
            35295,
            35295,
            35295,
            35295,
            35295,
            35295,
            35295,
            35295,
            35296
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            860309,
            1720618,
            2580927,
            2616222,
            2651517,
            2686812,
            2722107,
            2757402,
            2792697,
            2827992,
            2863287,
            2898583
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.3": {
    "id": "343505501967717026",
    "name": "30% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:09.328607",
    "lastUpdated": "2021-12-29T18:27:09.328616",
    "author": "John Smith",
    "criteria": {
      "name": "30% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.3,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 3294902,
      "ratio": 0.6421823232255311,
      "total": 5130789,
      "initialMonthlyContribAvg": 1098300,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 50064,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            1098300,
            1098300,
            1098302,
            50064,
            50064,
            50064,
            50064,
            50064,
            50064,
            50064,
            50064,
            50072
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            1098300,
            2196600,
            3294902,
            3344966,
            3395030,
            3445094,
            3495158,
            3545222,
            3595286,
            3645350,
            3695414,
            3745486
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-3M-0.5": {
    "id": "4645585492684780340",
    "name": "50% Top contributors in 3M",
    "creationDate": "2021-12-29T18:27:09.435311",
    "lastUpdated": "2021-12-29T18:27:09.435320",
    "author": "John Smith",
    "criteria": {
      "name": "50% Top contributors in 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": 0.5,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 4322435,
      "ratio": 0.8424503521778035,
      "total": 5130789,
      "initialMonthlyContribAvg": 1440811,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 69639,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            1440811,
            1440811,
            1440813,
            69639,
            69639,
            69639,
            69639,
            69639,
            69639,
            69639,
            69639,
            69646
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            1440811,
            2881622,
            4322435,
            4392074,
            4461713,
            4531352,
            4600991,
            4670630,
            4740269,
            4809908,
            4879547,
            4949193
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.02": {
    "id": "3472322896817899928",
    "name": "2% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:09.547258",
    "lastUpdated": "2021-12-29T18:27:09.547267",
    "author": "John Smith",
    "criteria": {
      "name": "2% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.02,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 396779,
      "ratio": 0.07368107516183119,
      "total": 5385087,
      "initialMonthlyContribAvg": 66129,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 4519,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            66129,
            66129,
            66129,
            66129,
            66129,
            66134,
            4519,
            4519,
            4519,
            4519,
            4519,
            4521
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            66129,
            132258,
            198387,
            264516,
            330645,
            396779,
            401298,
            405817,
            410336,
            414855,
            419374,
            423895
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.05": {
    "id": "5403626033093534218",
    "name": "5% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:09.692288",
    "lastUpdated": "2021-12-29T18:27:09.692298",
    "author": "John Smith",
    "criteria": {
      "name": "5% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.05,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 877693,
      "ratio": 0.16298585333904542,
      "total": 5385087,
      "initialMonthlyContribAvg": 146282,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 10997,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            146282,
            146282,
            146282,
            146282,
            146282,
            146283,
            10997,
            10997,
            10997,
            10997,
            10997,
            11000
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            146282,
            292564,
            438846,
            585128,
            731410,
            877693,
            888690,
            899687,
            910684,
            921681,
            932678,
            943678
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.1": {
    "id": "5235461233471165903",
    "name": "10% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:09.847893",
    "lastUpdated": "2021-12-29T18:27:09.847903",
    "author": "John Smith",
    "criteria": {
      "name": "10% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.1,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1604686,
      "ratio": 0.29798701488016816,
      "total": 5385087,
      "initialMonthlyContribAvg": 267447,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 20651,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            267447,
            267447,
            267447,
            267447,
            267447,
            267451,
            20651,
            20651,
            20651,
            20651,
            20651,
            20653
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            267447,
            534894,
            802341,
            1069788,
            1337235,
            1604686,
            1625337,
            1645988,
            1666639,
            1687290,
            1707941,
            1728594
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.2": {
    "id": "6237293526982499156",
    "name": "20% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:10.007488",
    "lastUpdated": "2021-12-29T18:27:10.007499",
    "author": "John Smith",
    "criteria": {
      "name": "20% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.2,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 2674487,
      "ratio": 0.49664694367983286,
      "total": 5385087,
      "initialMonthlyContribAvg": 445747,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 37349,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            445747,
            445747,
            445747,
            445747,
            445747,
            445752,
            37349,
            37349,
            37349,
            37349,
            37349,
            37351
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            445747,
            891494,
            1337241,
            1782988,
            2228735,
            2674487,
            2711836,
            2749185,
            2786534,
            2823883,
            2861232,
            2898583
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.3": {
    "id": "4373418547715651698",
    "name": "30% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:10.159523",
    "lastUpdated": "2021-12-29T18:27:10.159533",
    "author": "John Smith",
    "criteria": {
      "name": "30% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.3,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 3426600,
      "ratio": 0.6363128395140134,
      "total": 5385087,
      "initialMonthlyContribAvg": 571100,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 53147,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            571100,
            571100,
            571100,
            571100,
            571100,
            571100,
            53147,
            53147,
            53147,
            53147,
            53147,
            53151
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            571100,
            1142200,
            1713300,
            2284400,
            2855500,
            3426600,
            3479747,
            3532894,
            3586041,
            3639188,
            3692335,
            3745486
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-6M-0.5": {
    "id": "1269425046903809451",
    "name": "50% Top contributors in 6M",
    "creationDate": "2021-12-29T18:27:10.319869",
    "lastUpdated": "2021-12-29T18:27:10.319879",
    "author": "John Smith",
    "criteria": {
      "name": "50% Top contributors in 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": 0.5,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 4505586,
      "ratio": 0.836678404638588,
      "total": 5385087,
      "initialMonthlyContribAvg": 750931,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 73934,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            750931,
            750931,
            750931,
            750931,
            750931,
            750931,
            73934,
            73934,
            73934,
            73934,
            73934,
            73937
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            750931,
            1501862,
            2252793,
            3003724,
            3754655,
            4505586,
            4579520,
            4653454,
            4727388,
            4801322,
            4875256,
            4949193
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.02": {
    "id": "5700549112457750670",
    "name": "2% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:10.473119",
    "lastUpdated": "2021-12-29T18:27:10.473130",
    "author": "John Smith",
    "criteria": {
      "name": "2% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.02,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 419967,
      "ratio": 0.06991206202686,
      "total": 6007075,
      "initialMonthlyContribAvg": 34997,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            34997,
            35000
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            34997,
            69994,
            104991,
            139988,
            174985,
            209982,
            244979,
            279976,
            314973,
            349970,
            384967,
            419967
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.05": {
    "id": "3512068760090221384",
    "name": "5% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:10.625442",
    "lastUpdated": "2021-12-29T18:27:10.625454",
    "author": "John Smith",
    "criteria": {
      "name": "5% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.05,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 934120,
      "ratio": 0.15550330235597193,
      "total": 6007075,
      "initialMonthlyContribAvg": 77843,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77843,
            77847
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            77843,
            155686,
            233529,
            311372,
            389215,
            467058,
            544901,
            622744,
            700587,
            778430,
            856273,
            934120
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.1": {
    "id": "1543535406115933087",
    "name": "10% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:10.795612",
    "lastUpdated": "2021-12-29T18:27:10.795622",
    "author": "John Smith",
    "criteria": {
      "name": "10% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.1,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1710644,
      "ratio": 0.2847715402254841,
      "total": 6007075,
      "initialMonthlyContribAvg": 142553,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142553,
            142561
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            142553,
            285106,
            427659,
            570212,
            712765,
            855318,
            997871,
            1140424,
            1282977,
            1425530,
            1568083,
            1710644
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.2": {
    "id": "8969240178473556013",
    "name": "20% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:10.956556",
    "lastUpdated": "2021-12-29T18:27:10.956567",
    "author": "John Smith",
    "criteria": {
      "name": "20% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.2,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 2866005,
      "ratio": 0.4771049137891569,
      "total": 6007075,
      "initialMonthlyContribAvg": 238833,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238833,
            238842
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            238833,
            477666,
            716499,
            955332,
            1194165,
            1432998,
            1671831,
            1910664,
            2149497,
            2388330,
            2627163,
            2866005
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.3": {
    "id": "6433243991377105654",
    "name": "30% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:11.110769",
    "lastUpdated": "2021-12-29T18:27:11.110779",
    "author": "John Smith",
    "criteria": {
      "name": "30% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.3,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 3698893,
      "ratio": 0.6157560876133559,
      "total": 6007075,
      "initialMonthlyContribAvg": 308241,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308241,
            308242
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            308241,
            616482,
            924723,
            1232964,
            1541205,
            1849446,
            2157687,
            2465928,
            2774169,
            3082410,
            3390651,
            3698893
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COVERAGE-12M-0.5": {
    "id": "5716928832149739858",
    "name": "50% Top contributors in 12M",
    "creationDate": "2021-12-29T18:27:11.238775",
    "lastUpdated": "2021-12-29T18:27:11.238783",
    "author": "John Smith",
    "criteria": {
      "name": "50% Top contributors in 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COVERAGE",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": 0.5,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 4884186,
      "ratio": 0.8130722523024934,
      "total": 6007075,
      "initialMonthlyContribAvg": 407015,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407015,
            407021
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            407015,
            814030,
            1221045,
            1628060,
            2035075,
            2442090,
            2849105,
            3256120,
            3663135,
            4070150,
            4477165,
            4884186
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-1M-25000": {
    "id": "3759980167192695574",
    "name": "Monthly Cash Flow of 25000 AED for 1M",
    "creationDate": "2021-12-29T18:27:11.343989",
    "lastUpdated": "2021-12-29T18:27:11.343997",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 25000 AED for 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": null,
      "cashflow": {
        "amount": 25000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 25000,
      "ratio": 0.0050500561566244615,
      "total": 4950440,
      "initialMonthlyContribAvg": 25000,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            25000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-1M-50000": {
    "id": "9006407471907147846",
    "name": "Monthly Cash Flow of 50000 AED for 1M",
    "creationDate": "2021-12-29T18:27:11.447045",
    "lastUpdated": "2021-12-29T18:27:11.447054",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 50000 AED for 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": null,
      "cashflow": {
        "amount": 50000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 50000,
      "ratio": 0.010100112313248923,
      "total": 4950440,
      "initialMonthlyContribAvg": 50000,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            50000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-1M-100000": {
    "id": "8254864322240836958",
    "name": "Monthly Cash Flow of 100000 AED for 1M",
    "creationDate": "2021-12-29T18:27:11.543974",
    "lastUpdated": "2021-12-29T18:27:11.543983",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 100000 AED for 1M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "1M",
      "ratio": null,
      "cashflow": {
        "amount": 100000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 100000,
      "ratio": 0.020200224626497846,
      "total": 4950440,
      "initialMonthlyContribAvg": 100000,
      "initialCompliantMonthlyContribAvg": 81142,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 87517
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            100000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-3M-25000": {
    "id": "1273769496856720097",
    "name": "Monthly Cash Flow of 25000 AED for 3M",
    "creationDate": "2021-12-29T18:27:11.640223",
    "lastUpdated": "2021-12-29T18:27:11.640232",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 25000 AED for 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": null,
      "cashflow": {
        "amount": 25000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 75000,
      "ratio": 0.014617634831601924,
      "total": 5130789,
      "initialMonthlyContribAvg": 25000,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            25000,
            25000,
            25000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            25000,
            50000,
            75000,
            75000,
            75000,
            75000,
            75000,
            75000,
            75000,
            75000,
            75000,
            75000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-3M-50000": {
    "id": "7575120119994439387",
    "name": "Monthly Cash Flow of 50000 AED for 3M",
    "creationDate": "2021-12-29T18:27:11.784758",
    "lastUpdated": "2021-12-29T18:27:11.784769",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 50000 AED for 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": null,
      "cashflow": {
        "amount": 50000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 150000,
      "ratio": 0.029235269663203847,
      "total": 5130789,
      "initialMonthlyContribAvg": 50000,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            50000,
            50000,
            50000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            50000,
            100000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-3M-100000": {
    "id": "4979338780491863639",
    "name": "Monthly Cash Flow of 100000 AED for 3M",
    "creationDate": "2021-12-29T18:27:11.933564",
    "lastUpdated": "2021-12-29T18:27:11.933574",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 100000 AED for 3M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "3M",
      "ratio": null,
      "cashflow": {
        "amount": 100000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 300000,
      "ratio": 0.058470539326407694,
      "total": 5130789,
      "initialMonthlyContribAvg": 100000,
      "initialCompliantMonthlyContribAvg": 82074,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 88623
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            100000,
            100000,
            100000,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            100000,
            200000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-6M-25000": {
    "id": "8403525655142385744",
    "name": "Monthly Cash Flow of 25000 AED for 6M",
    "creationDate": "2021-12-29T18:27:12.044395",
    "lastUpdated": "2021-12-29T18:27:12.044404",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 25000 AED for 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": null,
      "cashflow": {
        "amount": 25000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 150000,
      "ratio": 0.02785470318306835,
      "total": 5385087,
      "initialMonthlyContribAvg": 25000,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            25000,
            50000,
            75000,
            100000,
            125000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000,
            150000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-6M-50000": {
    "id": "3235494222027430817",
    "name": "Monthly Cash Flow of 50000 AED for 6M",
    "creationDate": "2021-12-29T18:27:12.137732",
    "lastUpdated": "2021-12-29T18:27:12.137741",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 50000 AED for 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": null,
      "cashflow": {
        "amount": 50000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 300000,
      "ratio": 0.0557094063661367,
      "total": 5385087,
      "initialMonthlyContribAvg": 50000,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            50000,
            100000,
            150000,
            200000,
            250000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000,
            300000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-6M-100000": {
    "id": "3495317015813111871",
    "name": "Monthly Cash Flow of 100000 AED for 6M",
    "creationDate": "2021-12-29T18:27:12.224960",
    "lastUpdated": "2021-12-29T18:27:12.224969",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 100000 AED for 6M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": null,
      "cashflow": {
        "amount": 100000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 600000,
      "ratio": 0.1114188127322734,
      "total": 5385087,
      "initialMonthlyContribAvg": 100000,
      "initialCompliantMonthlyContribAvg": 83170,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 90801
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            100000,
            200000,
            300000,
            400000,
            500000,
            600000,
            600000,
            600000,
            600000,
            600000,
            600000,
            600000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-12M-25000": {
    "id": "3566139572548683969",
    "name": "Monthly Cash Flow of 25000 AED for 12M",
    "creationDate": "2021-12-29T18:27:12.330326",
    "lastUpdated": "2021-12-29T18:27:12.330334",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 25000 AED for 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": null,
      "cashflow": {
        "amount": 25000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 300000,
      "ratio": 0.049941111106486934,
      "total": 6007075,
      "initialMonthlyContribAvg": 25000,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000,
            25000
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            25000,
            50000,
            75000,
            100000,
            125000,
            150000,
            175000,
            200000,
            225000,
            250000,
            275000,
            300000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-12M-50000": {
    "id": "955698861411200794",
    "name": "Monthly Cash Flow of 50000 AED for 12M",
    "creationDate": "2021-12-29T18:27:12.438820",
    "lastUpdated": "2021-12-29T18:27:12.438828",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 50000 AED for 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": null,
      "cashflow": {
        "amount": 50000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 600000,
      "ratio": 0.09988222221297387,
      "total": 6007075,
      "initialMonthlyContribAvg": 50000,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000,
            50000
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            50000,
            100000,
            150000,
            200000,
            250000,
            300000,
            350000,
            400000,
            450000,
            500000,
            550000,
            600000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "CASHFLOW-12M-100000": {
    "id": "8127272215862116630",
    "name": "Monthly Cash Flow of 100000 AED for 12M",
    "creationDate": "2021-12-29T18:27:12.552461",
    "lastUpdated": "2021-12-29T18:27:12.552506",
    "author": "John Smith",
    "criteria": {
      "name": "Monthly Cash Flow of 100000 AED for 12M",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "CASHFLOW",
      "coverage_balance": 0,
      "period": "12M",
      "ratio": null,
      "cashflow": {
        "amount": 100000,
        "currency_code": "AED"
      }
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1200000,
      "ratio": 0.19976444442594773,
      "total": 6007075,
      "initialMonthlyContribAvg": 100000,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000,
            100000
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            100000,
            200000,
            300000,
            400000,
            500000,
            600000,
            700000,
            800000,
            900000,
            1000000,
            1100000,
            1200000
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  },
  "COMPLIANCE": {
    "id": "8816283255277625870",
    "name": "Full Compliance Contributions",
    "creationDate": "2021-12-29T18:27:12.660166",
    "lastUpdated": "2021-12-29T18:27:12.660174",
    "author": "John Smith",
    "criteria": {
      "name": "Full Compliance Contributions",
      "asOfDate": "2021-12-29T00:00:00Z",
      "start": "2021-12-31T00:00:00Z",
      "end": "2022-12-31T00:00:00Z",
      "freq": "M",
      "type": "COMPLIANCE",
      "coverage_balance": 0,
      "period": "6M",
      "ratio": null,
      "cashflow": null
    },
    "coverage": {
      "currency": "AED",
      "gratuity": 1043831,
      "ratio": 0.17376693315798455,
      "total": 6007075,
      "initialMonthlyContribAvg": 86985,
      "initialCompliantMonthlyContribAvg": 86985,
      "maintenanceMonthlyContribAvg": 0,
      "maintenanceCompliantMonthlyContribAvg": 0
    },
    "contributions": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Cash Flows",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        },
        {
          "name": "Compliant Monthly Contributions",
          "data": [
            81142,
            81847,
            83235,
            83785,
            84253,
            84761,
            87252,
            89137,
            90496,
            92123,
            92402,
            93398
          ]
        }
      ]
    },
    "liability": {
      "labels": [
        "12/21",
        "01/22",
        "02/22",
        "03/22",
        "04/22",
        "05/22",
        "06/22",
        "07/22",
        "08/22",
        "09/22",
        "10/22",
        "11/22"
      ],
      "series": [
        {
          "name": "Covered Liability",
          "data": [
            81142,
            162989,
            246224,
            330009,
            414262,
            499023,
            586275,
            675412,
            765908,
            858031,
            950433,
            1043831
          ]
        },
        {
          "name": "Total Liability",
          "data": [
            4950440,
            5042007,
            5130789,
            5216121,
            5299096,
            5385087,
            5485671,
            5598276,
            5700002,
            5806506,
            5903790,
            6007075
          ]
        }
      ]
    }
  }
}