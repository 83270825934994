import mock from "../mock";
import { paginateArray, sortCompare, uuidv4 } from "../../utility/fakeDbUtils";

/* eslint-disable */
const data = {
  QATESTME: {
    owner: "64c1f218-2378-41e2-b669-401ac02b9355",
    money_account: {
      owner: "64c1f218-2378-41e2-b669-401ac02b9355",
      currency: "AED",
      name: "Money Account",
      account_id: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
    },
    banking_accounts: [
      {
        account_name: "HSBC Account",
        iban: "DE75512108001245126199",
        currency: "AED",
        owner: "64c1f218-2378-41e2-b669-401ac02b9355",
        account_id: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
        open_banking_account_id: "c872caee-8cf3-41ed-b941-345c65575493",
        bank_identifier: "HSBC",
        account_number: "1245126199",
        status: "VALID",
      },
    ],
    investment_accounts: [],
  },
  emptyMe: {
    owner: "1f01d5e8-43fa-4b0b-a359-2b890f7085ce",
    money_account: {
      owner: "64c1f218-2378-41e2-b669-401ac02b9355",
      currency: "AED",
      name: "Money Account",
      account_id: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
    },
    banking_accounts: [],
    investment_accounts: [],
  },
  me: {
    owner: "64c1f218-2378-41e2-b669-401ac02b9355",
    money_account: {
      owner: "64c1f218-2378-41e2-b669-401ac02b9355",
      currency: "AED",
      name: "Money Account",
      account_id: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
    },
    banking_accounts: [
      {
        account_name: "HSBC Account",
        iban: "DE75512108001245126199",
        currency: "AED",
        owner: 1,
        account_id: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
        open_banking_account_id: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
        bank_identifier: "HSBC",
        account_number: "1245126199",
        status: "VALID",
      },
      {
        account_name: "BNP Account",
        iban: "DE75512108001245126199",
        currency: "AED",
        owner: 1,
        account_id: "c872caee-8cf3-41ed-b941-345c65575493",
        open_banking_account_id: "c872caee-8cf3-41ed-b941-345c65575493",
        bank_identifier: "BNP",
        account_number: "1245126199",
        status: "VALID",
      },
    ],
    investment_accounts: [
      {
        account_id: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
        label: "Ryan's College",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "investments",
        icon: "string",
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-01-30T08:30:00Z",
        last_updated_date: "2021-02-18T17:44:00Z",
      },
      {
        account_id: "2ae08f77-512b-45ef-87d3-40aca2db0bed",
        label: "Retirement Plan",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "investments",
        icon: "string",
        goal: {
          target_date: "2040-12-31T00:00:00Z",
          target_amount: {
            amount: 25000000,
            currency_code: "AED",
          },
        },
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-03-30T08:30:00Z",
        last_updated_date: "2021-06-18T17:44:00Z",
      },
      {
        account_id: "d6ebb76b-c82e-4ed1-8294-c4e9605095be",
        label: "Holiday 2021",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "savings",
        icon: "string",
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-01-30T08:30:00Z",
        last_updated_date: "2021-02-18T17:44:00Z",
      },
      {
        account_id: "08a73b13-10da-47a7-89df-0c29aff23874",
        label: "Emergency Fund",
        investment_definition_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        category: "savings",
        icon: "string",
        goal: {
          target_date: "2022-09-30T00:00:00Z",
          target_amount: {
            amount: 2500000,
            currency_code: "AED",
          },
        },
        status: "SUCCESS",
        status_detail: "Investment Accounts created",
        creation_date: "2021-03-30T08:30:00Z",
        last_updated_date: "2021-06-18T17:44:00Z",
      },
    ],
  },
  summary: {
    balance: {
      amount: 5000,
      currency: "AED",
    },
    currency: "AED",
    cash_flows: {
      labels: ["June 2021", "July 2021", "August 2021"],
      series: [
        {
          name: "Credit",
          data: [2040, 1850, 1950],
        },
        {
          name: "Debit",
          data: [450, 700, 150],
        },
      ],
    },
  },
  envTransferIntents: [
    {
      amount: 1500000,
      currency_code: "AED",
      source: "dc4627ff-065a-4547-a2f9-def219bf87e3",
      destination: "2ad9975f-32c1-48b3-9741-5a3981d8defe",
      date: "2021-10-19T05:19:25.309696Z",
      label: "Initial Contribution for Investments",
      intent_id: "d2fb7509-3bc7-4fb0-b384-27d4631a9a10",
      status: "REGISTERED",
      multileg: null,
      legs: null,
    },
    {
      amount: 150000,
      currency_code: "AED",
      source: "dc4627ff-065a-4547-a2f9-def219bf87e3",
      destination: "2ad9975f-32c1-48b3-9741-5a3981d8defe",
      date: "2021-10-19T05:17:18.128788Z",
      label: "Initial Contribution for Savings",
      intent_id: "ec506799-490b-4769-b6e2-26f9fa671dc4",
      status: "REGISTERED",
      multileg: null,
      legs: null,
    },
  ],
  transferIntents: [
    {
      id: 1,
      amount: 200000,
      currency_code: "AED",
      date: "2021-03-11T13:49:43.359663Z",
      destination: "08a73b13-10da-47a7-89df-0c29aff23874",
      label: "June Contribution",
      multileg: false,
      source: "c872caee-8cf3-41ed-b941-345c65575493",
      status: "EXECUTED",
      transfer_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
    {
      id: 2,
      amount: 300000,
      currency_code: "AED",
      date: "2021-05-25T07:54:27.117795600Z",
      destination: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
      label: "May Contribution",
      multileg: false,
      source: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
      status: "REGISTERED",
      transfer_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
    {
      id: 3,
      amount: 400000,
      currency_code: "AED",
      date: "2021-06-25T07:54:27.117795600Z",
      destination: "3b52dc63-6d63-4ce2-81f8-01e83d57be33",
      label: "June Contribution",
      multileg: false,
      source: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
      status: "REGISTERED",
      transfer_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
  ],
  transactions: [
    {
      amount: 2000,
      currency: "AED",
      date: "2021-05-25T07:54:27.117795600Z",
      destination: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
      intent_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      label: "June Contribution",
      source: "c872caee-8cf3-41ed-b941-345c65575493",
      status: "error",
      transaction_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
    {
      amount: 2001,
      currency: "AED",
      date: "2021-03-25T07:54:27.117795600Z",
      destination: "c872caee-8cf3-41ed-b941-345c65575493",
      intent_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      label: "June Contribution",
      source: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
      status: "error",
      transaction_id: "3fa85f64-5717-4562-b3fc-2c963f66afa1",
    },
  ],
  statements: [
    {
      id: "234234234",
      start: "2021-03-11T13:49:43.359663Z",
      end: "2021-04-11T13:49:43.359663Z",
      account_id: "08f98f73-279f-43f0-94b2-69ae6dd7d876",
    },
    {
      id: "34234234234",
      start: "2021-02-11T13:49:43.359663Z",
      end: "2021-04-11T13:49:43.359663Z",
      account_id: "0c883d02-31d9-478d-b356-ec907ab4d0fa",
    },
  ],
  bank: {
    account_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    banking_accounts: [
      {
        account_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        account_name: "HSBC AED Account",
        account_number: "0532013000",
        bank_identifier: "HSBC_UAE",
        currency: "AED",
        iban: "DE89370400440532013000",
        open_banking_account_id: "string",
        owner: "test owner 2",
        status: "VALID",
      },
      {
        account_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        account_name: "Emirates NBD",
        account_number: "723487234982",
        bank_identifier: "NBD",
        currency: "AED",
        iban: "AED348209482394824822094",
        open_banking_account_id: "string",
        owner: "test owner 1",
        status: "NOT_VALIDATED",
      },
    ],
    currency: "AED",
    owner: 0,
  },
  export: {
    description: "string",
    file: "string",
    filename: "transactions.pdf",
    inputStream: {},
    open: true,
    readable: true,
    uri: "string",
    url: "/template/v3/DefaultTemplateMandatoryFields.xlsx",
  },
  statementsExport: {
    description: "string",
    file: "string",
    filename: "statements.pdf",
    inputStream: {},
    open: true,
    readable: true,
    uri: "string",
    url: "/template/v3/DefaultTemplateMandatoryFields.xlsx",
  },
  ibansResponse: {
    account_number: "0532013000",
    bank_identifier: "37040044",
    country_code: "DE",
  },
  addBankAccountResponse: {
    account_name: "Test bank account",
    iban: "NL07INGB5373380466",
    currency: "AED",
    owner: "4b52d3268dc7638f9320e5b716e53a88",
    account_id: "c872caee-8cf3-41ed-b941-345c65575493",
    open_banking_account_id: "b6c79246-ff99-4576-9688-63ddb08c38d6",
    bank_identifier: "INGB",
    account_number: "5373380466",
    validated: "NOT_VALIDATED",
  },
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return the money accounts me
// ------------------------------------------------
mock.onGet("/api/finflx-accounts/me").reply(() => [200, data.me]);

// ------------------------------------------------
// POST: Add bank account
// ------------------------------------------------
mock.onPost(/\/api\/money-accounts\/.*\/banking-accounts/).reply(() => {
  const response = data.addBankAccountResponse;
  return [200, response];
});

// ------------------------------------------------
// GET: Return the money accounts summary view
// ------------------------------------------------
mock
  .onGet(/\/api\/money-accounts\/([A-Za-z0-9\-\\_]+)\/summary/)
  .reply(() => [200, data.summary]);

// ------------------------------------------------
// DELETE: Delete transfer intent
// ------------------------------------------------
mock.onDelete(/\/api\/transfer-intents\/\d+/).reply(() => [200]);

// // ------------------------------------------------
// // GET: Return the money accounts
// // ------------------------------------------------
// mock.onGet(/\/api\/money-accounts\/([0-9]+)/).reply(() => [200, data.bank])

// ------------------------------------------------
// DELETE: Delete money account
// ------------------------------------------------
mock.onDelete(/\/api\/money-accounts\/\d+/).reply(() => [200]);

// ------------------------------------------------
// DELETE: Delete bank account
// ------------------------------------------------
mock
  .onDelete(
    /\/api\/money-accounts\/([A-Za-z0-9\-\\_]+)\/banking-accounts\/([A-Za-z0-9\-\\_]+)/
  )
  .reply(() => [200]);

// ------------------------------------------------
// DOWNLOAD: Download transactions list
// ------------------------------------------------
mock.onGet("/api/transactions/export").reply(() => [200, data.export]);

// ------------------------------------------------
// POST: IBANs
// ------------------------------------------------
mock.onPost("/api/ibans").reply(() => {
  const response = data.ibansResponse;
  return [200, response];
});

// ------------------------------------------------
// GET: Return statements
// ------------------------------------------------
mock.onGet(/\/api\/statements?.*/).reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    perPage = 10,
    page = 1,
    sort = "id,desc",
    accountId = null,
    from = null,
    to = null,
  } = config.params;
  /* eslint-enable */

  /* eslint-disable */
  let filteredData;
  try {
    filteredData = data.statements.filter(
      (statement) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        // (transaction.date.toLowerCase().includes(queryLowered) ||
        statement.account_id === (accountId || statement.account_id) &&
        new Date(statement.start).getTime() >= new Date(from).getTime() &&
        new Date(statement.end).getTime() <= new Date(to).getTime()
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// DOWNLOAD Statements: Download specific statement on account
// ------------------------------------------------
mock
  .onGet(
    /\/api\/statements\/exports\/account\/([A-Za-z0-9\-\\_]+)\/([A-Za-z0-9\-\\_]+)\/\d+/
  )
  .reply(() => [200, data.statementsExport]);

// ------------------------------------------------
// DOWNLOAD All Statements: Download all statements
// ------------------------------------------------
mock
  .onGet(/\/api\/statements\/exports/)
  .reply(() => [200, data.statementsExport]);

// ------------------------------------------------
// POST: Add new transfer intents
// ------------------------------------------------
mock.onPost("/api/banking-accounts").reply((config) => {
  // Get event from post data
  const account = JSON.parse(config.data);

  const { length } = data.bank.banking_accounts;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.bank.banking_accounts[length - 1].id;
  }
  account.id = lastIndex + 1;
  account.account_id = uuidv4();

  data.bank.banking_accounts.push(account);

  return [201, account];
});

// ------------------------------------------------
// GET: Return the transfer intents
// ------------------------------------------------
mock.onGet("/api/transfer-intents").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    perPage = 10,
    page = 1,
    sort = "id,desc",
    accountId = null,
  } = config.params;
  /* eslint-enable */

  /* eslint-disable */
  let filteredData;
  try {
    filteredData = data.envTransferIntents.filter(
      (intent) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        intent.source === (accountId || intent.source)
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
  /* eslint-enable  */
});

// ------------------------------------------------
// POST: Add new transfer intents
// ------------------------------------------------
mock.onPost("/api/transfer-intents").reply((config) => {
  // Get event from post data
  const transferIntent = JSON.parse(config.data);

  const { length } = data.envTransferIntents;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.envTransferIntents[length - 1].id;
  }
  transferIntent.id = lastIndex + 1;
  transferIntent.status = "accepted";
  data.envTransferIntents.push(transferIntent);

  return [201, { transferIntent }];
});

// ------------------------------------------------
// PUT: Edit transfer intents
// ------------------------------------------------
mock.onPut("/api/transfer-intents").reply((config) => {
  // Get event from post data
  const transferIntent = JSON.parse(config.data);

  data.envTransferIntents = data.envTransferIntents.map((item) => {
    if (item.id === transferIntent.id) {
      return transferIntent;
    }
    return item;
  });
  return [201, { transferIntent }];
});

// ------------------------------------------------
// GET: Return transactions
// ------------------------------------------------
mock.onGet(/\/api\/transactions?.*/).reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    perPage = 10,
    page = 1,
    sort = "id,desc",
    account = null,
    from = null,
    to = null,
  } = config.params;
  /* eslint-enable */
  /* eslint-disable */
  let filteredData;
  try {
    filteredData = data.transactions.filter(
      (transaction) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        transaction.source === (account || transaction.source) &&
        transaction.date &&
        new Date(transaction.date).getTime() > new Date(from).getTime() &&
        new Date(transaction.date).getTime() < new Date(to).getTime()
    );
  } catch (e) {
    console.error(e.name + ": " + e.message);
  }

  const sortValue = sort.split(",")[0];
  const sortDesc = sort.split(",")[1] === "desc";
  const sortedData = filteredData.sort(sortCompare(sortValue));
  if (sortDesc) sortedData.reverse();

  /* eslint-disable object-shorthand */
  return [
    200,
    {
      content: paginateArray(sortedData, perPage, page + 1),
      totalElements: filteredData.length,
    },
  ];
  /* eslint-enable  */
});

mock
  .onGet(/\/api\/money-accounts\/([A-Za-z0-9\-\\_]+)\/transactions?.*/)
  .reply((config) => {
    // eslint-disable-next-line object-curly-newline
    const {
      perPage = 10,
      page = 1,
      sort = "id,desc",
      account = null,
      from = null,
      to = null,
    } = config.params;
    /* eslint-enable */

    /* eslint-disable */
    let filteredData;
    try {
      filteredData = data.transactions.filter(
        (transaction) =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          transaction.source === (account || transaction.source) &&
          transaction.date &&
          new Date(transaction.date).getTime() > new Date(from).getTime() &&
          new Date(transaction.date).getTime() < new Date(to).getTime()
      );
    } catch (e) {
      console.error(e.name + ": " + e.message);
    }

    const sortValue = sort.split(",")[0];
    const sortDesc = sort.split(",")[1] === "desc";
    const sortedData = filteredData.sort(sortCompare(sortValue));
    if (sortDesc) sortedData.reverse();

    /* eslint-disable object-shorthand */
    return [
      200,
      {
        content: paginateArray(sortedData, perPage, page + 1),
        totalElements: filteredData.length,
      },
    ];
    /* eslint-enable  */
  });
